import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { TextInput, View, Pressable, Platform, } from 'react-native';
import { Row, Icon } from '../lib';
import { PlatformsFontRegular } from '../atoms/typography';
import { colors, createStyles } from '../theme';
export const SearchInput = ({ collapsed, maxLength, onChange, onToggleCollapse, style, value, }) => {
    const isCollapsible = !!onToggleCollapse;
    const clearInput = useCallback(() => {
        if (isCollapsible && !collapsed && value === '') {
            onToggleCollapse?.();
        }
        onChange?.('');
    }, [collapsed, isCollapsible, value, onChange, onToggleCollapse]);
    return isCollapsible && collapsed ? (_jsx(View, { style: $.collapsedContainer, children: _jsx(Pressable, { onPress: onToggleCollapse, children: _jsx(Icon, { name: "search-lens", size: "sm", em: true }) }) })) : (_jsxs(Row.L, { px: "s", py: "xs", style: [
            $.inputContainer,
            style,
            // TODO(Hadrien): Manage disabled && inputStyles.disabled,
            // TODO(Hadrien): Manage error && inputStyles.error,
            // TODO(Hadrien): Manage isFocused && inputStyles.focused,
        ], children: [_jsx(Icon, { name: "search-lens", size: "sm", em: true }), _jsx(TextInput
            // @ts-expect-error this property is web specific
            , { 
                // @ts-expect-error this property is web specific
                style: [$.textInput, Platform.OS === 'web' && { outlineWidth: 0 }], autoFocus: isCollapsible, value: value, onChangeText: onChange, placeholder: "Search", keyboardType: "web-search", returnKeyType: "search", allowFontScaling: false, autoCapitalize: "none", autoCorrect: false, maxLength: maxLength, keyboardAppearance: "dark", numberOfLines: 1, placeholderTextColor: colors.onTextInputDim, selectionColor: colors.onTextInputEm, underlineColorAndroid: colors.transparent }), (value.length > 0 || (isCollapsible && !collapsed)) && (_jsx(Pressable, { onPress: clearInput, style: $.clearIcon, children: _jsx(Icon, { name: "close-x", size: "sm", em: true }) }))] }));
};
const $ = createStyles({
    collapsedContainer: {
        borderRadius: 'rad2',
        backgroundColor: 'backgroundAlt',
        ml: 's',
        p: 'xs',
    },
    inputContainer: {
        justifyContent: 'center',
        borderRadius: 'rad2',
        backgroundColor: 'backgroundAlt',
        borderColor: 'transparent',
        maxHeight: 32,
    },
    textInput: {
        mx: 'xs',
        fontSize: 12,
        lineHeight: 16,
        height: 16,
        ...PlatformsFontRegular,
        color: 'onTextInput',
        padding: 0,
        flexGrow: 1,
        backgroundColor: 'backgroundAlt',
        borderColor: 'transparent',
        borderRadius: 'rad1', // TODO: @Jey better naming for border rardius sizes
    },
    clearIcon: {
        position: 'absolute',
        right: 12,
    },
});
