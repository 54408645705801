import { jsx as _jsx } from "react/jsx-runtime";
import { API } from 'api';
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { Icon } from '../lib';
export function SortDirectionIcon({ active = false, dir, onChange }) {
    // Handlers
    const handlePress = useCallback(() => {
        onChange?.(dir === API.SortDirection.Ascending
            ? API.SortDirection.Descending
            : API.SortDirection.Ascending);
    }, [dir, onChange]);
    // Render
    return (_jsx(Pressable, { disabled: !onChange, onPress: handlePress, children: _jsx(Icon, { name: dir === API.SortDirection.Descending ? 'arrow-down' : 'arrow-up', color: active ? 'onSurfaceEm' : 'onSurfaceDim', size: "xs" }) }));
}
