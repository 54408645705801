import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { TextCaption } from '../lib';
import { createStyles } from '../theme';
export const PaginationPage = ({ active = false, value, onPress }) => {
    // Handlers
    const handlePress = useCallback(() => {
        onPress?.(value);
    }, [onPress, value]);
    // Render
    return (_jsx(Pressable, { style: $.container, disabled: !onPress, onPress: handlePress, children: _jsx(TextCaption, { em: active, children: value }) }));
};
const $ = createStyles({
    container: {
        px: 'xxs',
    },
});
