import { useCallback, useMemo } from 'react';
import { handleNavigation, QueryParams } from '../shared/url-params/url-params';

export enum SEARCH_PARAMS {
  TAB_INDEX = 'tabIndex',
}

export const useAppTabs = <TabKey extends string = 'tabIndex'>(
  customParamOptions?: Record<string, string | number>,
  tabKey?: TabKey
) => {
  const key = (tabKey as string) || 'tabIndex';
  /**
   * Hooks
   */
  const { applyParams, queryParams } = handleNavigation<TabKey>();
  const tabIndex = queryParams[key] as string;

  const handleOnTabClicked = useCallback(
    async (tab: number | string) => {
      applyParams({
        [key]: customParamOptions
          ? Object.values(customParamOptions)[tab]
          : tab,
      } as QueryParams<TabKey>);
    },
    [applyParams, customParamOptions]
  );
  const tab = useMemo(() => {
    if (!tabIndex) {
      return 0;
    }
    if (!customParamOptions?.[tabIndex]) {
      return Number(tabIndex);
    }
    return Object.values(customParamOptions).indexOf(tabIndex);
  }, [customParamOptions, tabIndex]);

  /**
   * Return
   */
  return {
    [key]: tab,
    handleOnTabClicked,
  } as {
    [k in TabKey]: number;
  } & { handleOnTabClicked: (tab: number | string) => Promise<void> };
};
