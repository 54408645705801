export const theme = {
  color: {
    background: {
      primary: '#58ffb2',
      secondary: '#181428',
      tertiary: '#defff0',
      success: '#46d862',
      error: '#ff4621',
      warning: '#ffe65a',
      neutral: '#434343',
      white: '#ffffff',
    },
    border: {
      primary: '#ececec',
      secondary: '#dbdbdb',
      tertiary: '#181428',
      info: '#5262df',
      success: '#229437',
      error: '#bb2102',
      warning: '#ffe65a',
    },
    text: {
      primary: '#181428',
      secondary: '#676767',
      tertiary: '#959595',
      info: '#5262df',
      success: '#229437',
      error: '#bb2102',
      warning: '#b79418',
      inverse: '#ffffff',
      placeholder: '#b8b8b8',
      disabled: '#dbdbdb',
    },
    link: {
      default: '#181428',
      hover: '#201b35',
      active: '#282142',
      visited: '#282142',
    },
    button: {
      primary: {
        default: '#58ffb2',
        hover: '#46cc8e',
        pressed: '#3eb37d',
        focused: '#58ffb2',
      },
      secondary: {
        default: '#181428',
        hover: '#201b35',
        pressed: '#282142',
        focused: '#181428',
      },
      tertiary: {
        default: '#ffffff',
        hover: '#fafafa',
        pressed: '#f8f8f8',
        focused: '#ffffff',
      },
      success: {
        default: '#229437',
        pressed: '#147226',
        hover: '#0a5018',
        focused: '#229437',
      },
      error: {
        default: '#bb2102',
        hover: '#991a00',
        pressed: '#771400',
        focused: '#bb2102',
      },
    },
    icon: {
      default: '#181428',
      inverse: '#ffffff',
      disabled: '#dbdbdb',
    },
    input: {
      border: {
        default: '#ececec',
        hover: '#dbdbdb',
        active: '#181428',
      },
      background: '#ffffff',
    },
    brand: {
      '100': '#defff0',
      '200': '#c7ffe5',
      '300': '#90ffcc',
      '400': '#74ffbf',
      '500': '#58ffb2',
      '600': '#46cc8e',
      '700': '#3eb37d',
      '800': '#35996b',
      '900': '#2c8059',
    },
    deep_blue: {
      '100': '#806eca',
      '200': '#473b76',
      '300': '#473b76',
      '400': '#3f3569',
      '500': '#372e5c',
      '600': '#30284f',
      '700': '#282142',
      '800': '#201b35',
      '900': '#181428',
    },
    tertiary: {
      '100': '#fefdfb',
      '200': '#fefcf8',
      '300': '#fdf9f4',
      '400': '#fbf5f0',
      '500': '#f9f1ea',
      '600': '#d6bcab',
      '700': '#b38c75',
      '800': '#90604a',
      '900': '#773f2c',
    },
    light_blue: {
      '100': '#f1f7f8',
      '200': '#e4f0f2',
      '300': '#d7e9ec',
      '400': '#cae1e5',
      '500': '#bcdadf',
      '600': '#afd3d9',
      '700': '#a2cbd2',
      '800': '#95c4cc',
      '900': '#87bdc5',
    },
    blue: {
      '100': '#dce0f9',
      '200': '#c5cbf4',
      '300': '#8c96ea',
      '400': '#6f7ce4',
      '500': '#5262df',
      '600': '#4452ba',
      '700': '#3f4b9c',
      '800': '#293170',
      '900': '#1b214a',
    },
    purple: {
      '100': '#eaddf7',
      '200': '#dcc6f1',
      '300': '#b98ee3',
      '400': '#a771dc',
      '500': '#9655d5',
      '600': '#7d47b1',
      '700': '#64398e',
      '800': '#593a78',
      '900': '#321c47',
    },
    brown: {
      '100': '#ffe5e0',
      '200': '#ffd4cb',
      '300': '#ffe5e0',
      '400': '#fe937c',
      '500': '#fe7d62',
      '600': '#d46852',
      '700': '#bd5f4b',
      '800': '#7f3f31',
      '900': '#552a21',
    },
    green: {
      '100': '#cbffd5',
      '200': '#a7ffb8',
      '300': '#83ff9b',
      '400': '#5dfa7b',
      '500': '#46d862',
      '600': '#32b64b',
      '700': '#229437',
      '800': '#147226',
      '900': '#0a5018',
    },
    red: {
      '100': '#ffece9',
      '200': '#ffc3b7',
      '300': '#ff9985',
      '400': '#ff7053',
      '500': '#ff4621',
      '600': '#dd3210',
      '700': '#bb2102',
      '800': '#991a00',
      '900': '#771400',
    },
    yellow: {
      '100': '#fffad5',
      '200': '#fff4ac',
      '300': '#ffec82',
      '400': '#ffe563',
      '500': '#ffe65a',
      '600': '#dbb523',
      '700': '#b79418',
      '800': '#93730f',
      '900': '#504700',
    },
    gray: {
      '100': '#fafafa',
      '200': '#f8f8f8',
      '300': '#ececec',
      '400': '#dbdbdb',
      '500': '#b8b8b8',
      '600': '#959595',
      '700': '#808080',
      '800': '#676767',
      '900': '#434343',
      white: '#ffffff',
    },
  },
  spacing: {
    '1': '2px',
    '2': '4px',
    '3': '6px',
    '4': '8px',
    '5': '12px',
    '6': '16px',
    '7': '20px',
    '8': '24px',
    '9': '32px',
    '10': '40px',
    '11': '48px',
    '12': '56px',
    '13': '64px',
    '14': '72px',
    '15': '80px',
    '16': '96px',
    '17': '112px',
  },
  radius: {
    sm: '4px',
    md: '8px',
    lg: '16px',
    full: '9999px',
  },
  Drop1: '0 0 25px 0px #00000014',
  Drop2: '0 0 20px 0px #0000000f',
  Drop3: '0 0 64px 0px #0000001f',
  'Drop1 DM': '0 0 25px 0px #193843cc',
  'Drop2 DM': '0 0 20px 0px #19384399',
  'dp store': '0 0 64px 0px #0000001f',
  Body: {
    Medium: {
      Regular: '400 14px/20px Inter',
      Bold: '700 14px/20px Inter',
    },
    Small: {
      Regular: '400 12px/16px Inter',
      Bold: '700 12px/16px Inter',
    },
    'Extra Small': {
      Bold: '700 10px/14px Inter',
      Regular: '400 10px/14px Inter',
    },
    Base: {
      Regular: '400 16px/24px Inter',
      Bold: '700 16px/24px Inter',
    },
  },
  Title: {
    '1': "400 48px/125% 'Noe Text'",
    '2': "400 40px/125% 'Noe Text'",
  },
  Heading: {
    '1': '700 48px/125% Inter',
    '2': '700 40px/125% Inter',
    '3': '700 32px/137.5% Inter',
    '4': '700 24px/137.5% Inter',
    '5': '700 20px/28px Inter',
    '6': '700 16px/20px Inter',
  },
};