import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { API } from 'api';
import { Row } from '../lib';
import { SortableHeaders } from '../list';
import { createStyles } from '../theme';
import { MARKETS_HEADERS } from './constants';
const FIAT_HEADERS1 = [MARKETS_HEADERS.NAME];
const FIAT_HEADERS2 = [
    MARKETS_HEADERS.PRICE,
    MARKETS_HEADERS.PERCENTAGE_CHANGE,
];
const FUND_HEADERS1 = [MARKETS_HEADERS.FUND_NAME];
const FUND_HEADERS2 = [MARKETS_HEADERS.PERFORMANCE];
const BOND_HEADERS1 = [MARKETS_HEADERS.BOND_NAME];
const BOND_HEADERS2 = [MARKETS_HEADERS.YIELD];
const CRYPTO_HEADERS1 = [MARKETS_HEADERS.NAME, MARKETS_HEADERS.MARKET_CAP];
const CRYPTO_HEADERS2 = [
    MARKETS_HEADERS.PRICE,
    MARKETS_HEADERS.PERCENTAGE_CHANGE,
];
export function MarketListHeader({ assetType, ...sortable }) {
    let content = null;
    if (assetType === API.MarketAssetType.Fiat) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, { items: FIAT_HEADERS1, ...sortable }), _jsx(SortableHeaders, { items: FIAT_HEADERS2 })] }));
    }
    if (assetType === API.MarketAssetType.Fund) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, { items: FUND_HEADERS1, ...sortable }), _jsx(SortableHeaders, { items: FUND_HEADERS2 })] }));
    }
    if (assetType === API.MarketAssetType.Bond) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, { items: BOND_HEADERS1, ...sortable }), _jsx(SortableHeaders, { items: BOND_HEADERS2 })] }));
    }
    if (assetType === API.MarketAssetType.Crypto) {
        content = (_jsxs(_Fragment, { children: [_jsx(SortableHeaders, { items: CRYPTO_HEADERS1, ...sortable }), _jsx(SortableHeaders, { items: CRYPTO_HEADERS2, ...sortable })] }));
    }
    return _jsx(Row.LR, { style: $.header, children: content });
}
const $ = createStyles({
    header: {
        backgroundColor: 'surfaceAlt',
        px: 'm',
        py: 's',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: 'outline',
    },
});
