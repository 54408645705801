import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { AmountChangePercent } from '../amount-format';
import { TableCell, TableRow } from '../table';
import { TableCellAsset } from './table-cell-asset';
import { FUND_HEADERS as HEADERS } from './market-table-header';
import { TextBody } from '../lib';
export const MarketTableItemFund = memo(({ currencyCode, data, onPress, ...rest }) => {
    // Handlers
    const handlePress = useCallback(() => {
        onPress?.(currencyCode);
    }, [currencyCode, onPress]);
    const cellProps = {
        align: 'right',
        onPress: onPress ? handlePress : undefined,
    };
    // Render
    return (_jsxs(TableRow, { children: [_jsx(TableCellAsset, { ...rest, onPress: onPress ? handlePress : undefined, width: HEADERS[0]?.width }), _jsx(TableCell, { width: HEADERS[1]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(TextBody, { color: "onSurfaceEm", em: true, children: data.currency }) }), _jsx(TableCell, { width: HEADERS[2]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountChangePercent, { value: data.mtdRoR, em: true }) }), _jsx(TableCell, { width: HEADERS[3]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountChangePercent, { value: data.ytdRoR, em: true }) }), _jsx(TableCell, { width: HEADERS[4]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountChangePercent, { value: data.itdRoR, em: true }) }), _jsx(TableCell, { width: HEADERS[5]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountChangePercent, { value: data.maxMonthlyDrawdown, em: true }) })] }));
});
