import { FC, useCallback } from 'react';
import { Field, FieldProps } from './field';
import { InputProps, NumberInput, NumberInputProps } from 'common';

type Props = FieldProps & NumberInputProps & InputProps;

export const NumberField: FC<Props> = ({
  label,
  name,
  onChange,
  validator,
  validate,
  ...rest
}) => {
  /**
   * DOM
   */
  return (
    <Field name={name} label={label} validator={validator} validate={validate}>
      {(field, meta, helpers) => {
        const hasError = !!meta.error && !!meta.touched;
        const handleOnChange = useCallback(
          value => {
            onChange?.(value);
            helpers.setValue(value);
          },
          [onChange]
        );
        return (
          <NumberInput
            {...rest}
            {...field}
            errored={Boolean(hasError)}
            onChange={handleOnChange}
          />
        );
      }}
    </Field>
  );
};
