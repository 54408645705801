import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import is from 'is';

export type QueryParams<K extends string> = {
  [key in K]?: unknown;
};

export type OptionsParams<T extends string> = {
  append?: boolean;
  replace?: boolean;
  callBack?: (args: QueryParams<T>) => void;
};

type QueryProps<T extends string> = {
  queryParams: QueryParams<T>;
  applyParams: (value: QueryParams<T>, options?: OptionsParams<T>) => void;
};

export const handleNavigation = <T extends string>(
  arg?: QueryParams<T>
): QueryProps<T> => {
  /**
   * Hooks
   */
  const navigate = useNavigate();

  /*remove empty / null fields*/
  const handleCleanUpParams = (params: QueryParams<T>): QueryParams<T> =>
    Object.keys(params).reduce((acc, key) => {
      if (!params[key]) {
        return acc;
      }

      return {
        ...acc,
        [key]: params[key],
      };
    }, {});

  const queryParams = useMemo(() => {
    const params: QueryParams<T> = {};
    const searchParams = new URLSearchParams(location.search);

    /**TODO - Needs improving for unknown data */
    for (const [key, val] of searchParams) {
      params[key] = val;
    }
    return handleCleanUpParams(params);
  }, [location.search]);

  useEffect(() => {
    if (!Object.values(queryParams).length && arg) {
      applyParams(arg);
    }
  }, []);

  const applyParams = useCallback(
    (value: QueryParams<T>, options?: OptionsParams<T>) => {
      let updatedParams = handleCleanUpParams(
        options?.append ?? true
          ? {
              ...queryParams,
              ...value,
            }
          : value
      );

      navigate(
        `${location.pathname}?${new URLSearchParams(
          updatedParams as URLSearchParams
        )}`,
        { replace: options?.replace ?? true }
      );

      if (!options?.callBack) {
        return;
      }

      options.callBack(updatedParams);
    },
    [queryParams, navigate, handleCleanUpParams]
  );

  return { applyParams, queryParams };
};
