import { jsx as _jsx } from "react/jsx-runtime";
import { Row } from '../lib';
import { resetZindexOptionally } from '../utils';
export function TableRow({ style, ...rest }) {
    /**
     * DOM
     */
    const computedStyles = resetZindexOptionally([
        style,
        {
            width: '100%',
        },
    ]);
    return _jsx(Row.TBL, { ...rest, style: computedStyles });
}
