import { API } from 'api';
import { StablehousePusherChannel, subscribePusher } from '../subscribe-pusher';
import { auditTime, Subject } from 'rxjs';

type Config = {
  pusher: any;
  env: string;
  tenant: string;
};

export const MarketsDataAggregator = () => {
  /**
   * Vars
   */
  let _config: Config | null = null;

  const _subscribedCodes: Set<string> = new Set();
  let _subs: (() => void)[] = [];

  const _subject: Subject<API.ListAssetDetailsResponse> =
    new Subject<API.ListAssetDetailsResponse>();
  let _data: Record<string, API.ListAssetDetailsResponse> | null = null;

  /**
   * Methods
   */
  const _handleDataFeed = (err: null | unknown, data) => {
    if (err) {
      console.error(`Error pusher> market`, err);
      return;
    }

    const message = data as unknown as API.ListAssetDetailsResponse;
    if (!message.code || !_data) {
      return;
    }
    _data[message.code] = message;
    _subject.next(message);
  };

  const intialize = (c: Config) => {
    if (_config) {
      return;
    }
    _config = c;
    _data = {};
  };

  const receive = (codes: string[]) => {
    if (!_config || !_config.tenant) {
      return;
    }
    if (!codes || !codes.length) {
      return;
    }
    for (const code of codes) {
      if (_subscribedCodes.has(code)) {
        return;
      }
      const unsub = subscribePusher(
        _config.pusher,
        StablehousePusherChannel.MARKETS,
        _config.env,
        _handleDataFeed,
        _config.tenant.toLowerCase(),
        code
      );
      if (unsub) {
        _subscribedCodes.add(code);
        _subs.push(unsub);
      }
    }
  };

  const get = (
    codes: string[]
  ): {
    assetCodesWithoutData: string[];
    aggregatedData: API.ListAssetDetailsResponse[];
    hasAllData: boolean;
  } => {
    const assetCodesWithoutData: string[] = [];
    const result: API.ListAssetDetailsResponse[] = [];

    if (!_data) {
      return {
        assetCodesWithoutData: codes,
        aggregatedData: result,
        hasAllData: false,
      };
    }

    for (const code of codes) {
      const d = _data[code];
      if (!d) {
        assetCodesWithoutData.push(code);
        continue;
      }
      result.push(d);
    }

    return {
      assetCodesWithoutData,
      aggregatedData: result,
      hasAllData: assetCodesWithoutData.length === 0,
    };
  };

  const flush = () => {
    _config = null;

    for (const unsub of _subs) {
      unsub();
    }
    _subs = [];
    _subscribedCodes.clear();

    _data = null;
    _subject.complete();
  };

  /**
   * Result
   */
  return {
    intialize,
    receive,
    get,
    /**
     * Note:
     * Pricing data is streamed say 4 times or N times if any of the props to be streamed changes, so if one of price, 24h ago, marketcap changes
     * we get 3 data feeds on the websocket
     *
     * To avoid updating the subscribers N times on each tick, the throttler allows for all messages to flow through and update on the last tick
     * I (@jey) chose 2 upon some testing.
     */
    feed: _subject.pipe(auditTime(2)),
    flush,
  };
};

export type MarketsDataAggregatorType = ReturnType<
  typeof MarketsDataAggregator
>;
