import {
  getYear,
  subMonths,
  subYears,
  startOfYear,
  lastDayOfYear,
} from 'date-fns';
import { DateRangeKey } from './formik/transaction-filter/types';

type DateRange = {
  text: string;
  startDate: Date;
  endDate: Date;
};

const now = new Date();

export const dateRanges: Record<DateRangeKey, DateRange> = {
  thisYear: {
    text: 'This year',
    startDate: startOfYear(now),
    endDate: now,
  },
  lastSixMonths: {
    text: 'Last 6 months',
    startDate: subMonths(now, 6),
    endDate: now,
  },
  lastTwelveMonths: {
    text: 'Last 12 months',
    startDate: subMonths(now, 12),
    endDate: now,
  },
  previousYear: {
    text: String(getYear(subYears(now, 1))),
    startDate: startOfYear(subYears(now, 1)),
    endDate: lastDayOfYear(subYears(now, 1)),
  },
  previousTwoYear: {
    text: String(getYear(subYears(now, 2))),
    startDate: startOfYear(subYears(now, 2)),
    endDate: lastDayOfYear(subYears(now, 2)),
  },
};
