import { API } from 'api';
export const MARKETS_HEADERS = {
    COUPON: {
        label: 'Coupon',
    },
    CURRENCY: {
        label: 'Currency',
    },
    FREQUENCY: {
        label: 'Frequency',
    },
    ISSUANCE_AMOUNT: {
        label: 'Issuance amount',
    },
    MATURITY: {
        label: 'Maturity',
    },
    NAME: {
        id: API.MarketListAssetsSortType.DisplayCode,
        label: 'Asset',
        defaultSortDir: API.SortDirection.Ascending,
    },
    FUND_NAME: {
        id: API.MarketListAssetsSortType.DisplayCode,
        label: 'Fund name',
    },
    BOND_NAME: {
        id: API.MarketListAssetsSortType.DisplayCode,
        label: 'Bond name',
    },
    PRICE: {
        id: API.MarketListAssetsSortType.Price,
        label: 'Price',
    },
    PERCENTAGE_CHANGE: {
        id: API.MarketListAssetsSortType.PercentageChange,
        label: '24h Change',
    },
    MARKET_CAP: {
        id: API.MarketListAssetsSortType.MarketCap,
        label: 'Market cap',
    },
    MMD: {
        label: 'MMD¹',
    },
    PERFORMANCE: {
        label: 'Performance',
    },
    ROR_MTD: {
        label: 'RoR (MTD)',
    },
    ROR_YTD: {
        label: 'RoR (YTD)',
    },
    TOTAL_RETURN: {
        label: 'Return since inception',
    },
    VOLUME: {
        id: API.MarketListAssetsSortType.Volume,
        label: 'Volume',
    },
    YIELD: {
        label: 'Yield',
    },
};
