import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { YieldLabel } from '../label';
import { AmountPercent, AmountPrice } from '../amount-format';
import { DateMaturity } from '../date-format';
import { LabeledValue, LabeledValueList } from '../labeled-value';
import { _MarketListItem } from './_market-list-item';
export const MarketListItemBond = memo(({ data, description, displayCode, ...rest }) => {
    return (_jsx(_MarketListItem, { ...rest, displayCode: displayCode, subtitle: description, addon: data.yieldPercent ? (_jsx(YieldLabel, { value: data.yieldPercent })) : undefined, footer: _jsxs(LabeledValueList, { children: [_jsx(LabeledValue, { label: "Maturity", value: _jsx(DateMaturity, { em: true, value: data.maturity }) }), _jsx(LabeledValue, { label: "Coupon", value: _jsx(AmountPercent, { em: true, value: data.couponPercent }) }), _jsx(LabeledValue, { label: "Price", value: _jsx(AmountPrice, { em: true, value: data.price }) })] }) }));
});
