import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Icon, Col } from '../lib';
import { colors } from '../theme';
export const BadgeIcon = memo(({ color: colorName = 'surfaceAccentActive', iconColor: iconColorName, size = 'md', ...rest }) => {
    const backgroundColor = colors[colorName];
    const isDark = colorName === 'primary' ||
        colorName === 'error' ||
        colorName === 'success';
    // || (colorName === 'accent' && rest.name === 'logo'); // TODO(Hadrien): Leaving logo for later
    const iconColor = iconColorName ?? (isDark ? 'onSurfaceInv' : 'onSurfaceEm');
    return (_jsx(Col.C, { style: [
            $.container,
            { backgroundColor },
            size === 'lg' && $.large,
            size === 'md' && $.medium,
            size === 'sm' && $.small,
            size === 'xs' && $.xsmall,
        ], children: _jsx(Icon, { ...rest, size: size, color: iconColor }) }));
});
const $ = StyleSheet.create({
    container: {
        height: 48,
        width: 48,
        borderRadius: 24,
    },
    xsmall: {
        height: 24,
        width: 24,
        borderRadius: 12,
    },
    small: {
        height: 32,
        width: 32,
        borderRadius: 16,
    },
    medium: {
        height: 36,
        width: 36,
        borderRadius: 18,
    },
    large: {
        height: 80,
        width: 80,
        borderRadius: 40,
    },
});
