import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { AmountChangePercent, AmountPrice } from '../amount-format';
import { Col } from '../lib';
import { createStyles } from '../theme';
export const MarketListItemAddonPrice = memo(({ data }) => {
    return (_jsxs(Col.R, { children: [_jsx(AmountPrice, { em: true, value: data.price }), _jsx(Col, { style: $.space, children: _jsx(AmountChangePercent, { value: data.change24hPercent }) })] }));
});
const $ = createStyles({
    space: {
        marginTop: 2,
    },
});
