import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { API } from 'api';
import { Fragment, useCallback, useMemo } from 'react';
import { TextCaption, Row, Col } from '../lib';
import { SortableHeaderTitle } from './sortable-header-title';
import { SortDirectionIcon } from './sortable-direction-icon';
export function SortableHeaders({ items, onSort, sortId, sortDir, arrowSide, }) {
    // Vars
    const isActive = useMemo(() => {
        if (sortId) {
            return items.some(item => item.id === sortId);
        }
        return false;
    }, [items, sortId]);
    // Handlers
    const handlePressId = useCallback((newSortId) => {
        if (newSortId === sortId) {
            // If already active, opposite sort direction
            onSort?.(newSortId, sortDir === API.SortDirection.Ascending
                ? API.SortDirection.Descending
                : API.SortDirection.Ascending);
        }
        else {
            const item = items.find(_item => _item.id === newSortId);
            if (item) {
                onSort?.(newSortId, item.defaultSortDir ?? API.SortDirection.Descending);
            }
        }
    }, [items, onSort, sortDir, sortId]);
    const handleChangeDir = useCallback((newDir) => {
        if (isActive) {
            if (sortId) {
                onSort?.(sortId, newDir);
            }
        }
        else {
            // If not active yet, grab the first sortable item by default
            const item = items[0];
            if (item?.id) {
                onSort?.(item.id, item.defaultSortDir ?? API.SortDirection.Descending);
            }
        }
    }, [isActive, items, onSort, sortId]);
    const sortIcon = !!sortDir && (_jsx(Col, { mr: arrowSide === 'left' ? 'xxs' : undefined, ml: arrowSide !== 'left' ? 'xxs' : undefined, children: _jsx(SortDirectionIcon, { active: isActive, onChange: handleChangeDir, dir: isActive ? sortDir : API.SortDirection.Ascending }) }));
    // Render
    return (_jsxs(Row.L, { children: [arrowSide === 'left' && sortIcon, items.map((item, index) => (_jsxs(Fragment, { children: [_jsx(SortableHeaderTitle, { id: item.id, label: item.label, active: !!item.id && sortId === item.id, onPress: handlePressId }, item.label), index < items.length - 1 && (_jsxs(TextCaption, { em: true, color: "onSurfaceDim", children: [' ', "/", ' '] }))] }, item.label))), !!sortDir && arrowSide !== 'left' && (_jsx(Col, { ml: "xxs", children: _jsx(SortDirectionIcon, { active: isActive, onChange: handleChangeDir, dir: isActive ? sortDir : API.SortDirection.Ascending }) }))] }));
}
