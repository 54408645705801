import { FC, useCallback, useEffect, useState } from 'react';
import { DataStore } from '../../store';
import { AppTablePaginator as Paginator } from '../app-table-paginator';
import { API } from 'api';
import { AppTableHeadings } from '../app-table/headings';
import {
  getPendingAprovalsColumns,
  PendingApprovalsColumn,
} from '../dashboard/apex-quorum-tables/columns';
import { AppTableRows } from '../app-table/rows';
import { SortInfo } from '~/store/types';
import cx from 'classnames';
import { AppContainer, useAppDialog } from 'common';
import { QuorumApprovalComponent } from './quorum-approval-component';
import { DashboardHeader } from '../dashboard/dashboard-header';
import { SearchEmpty } from '@xbto/universal-components';

export const QuorumPendingApprovalsComponent: FC = () => {
  /**
   * Store
   */
  const pendingOperations = DataStore.useStoreState(
    s => s.quorum.pendingOperations
  );
  const getPendingOperations = DataStore.useStoreActions(
    s => s.quorum.getPendingOperations
  );
  const removeHeader = DataStore.useStoreActions(s => s.removeHeader);

  /**
   *  State
   */
  const [pendingOperationsRequest, setPendingOperationsRequest] =
    useState<API.PendingOperationsRequest>({
      page: 1,
      types: null,
      entityIds: null,
    });
  const [sortInfo, setSortInfo] =
    useState<SortInfo<PendingApprovalsColumn> | null>(null);

  /**
   * Hooks
   */
  const { showDialog, hideDialog } = useAppDialog();
  useEffect(() => {
    removeHeader('x-impersonated-account-id');
    getPendingOperations({
      isBackgroundXHR: true,
      request: pendingOperationsRequest,
    });
  }, [
    pendingOperationsRequest.page,
    pendingOperationsRequest.types?.length,
    pendingOperationsRequest.entityIds?.length,
  ]);

  const handleDismiss = useCallback(() => {
    hideDialog();
  }, []);

  /**
   * DOM
   */
  const columnProps = {
    sort: sortInfo,
    onSort: (value: SortInfo<PendingApprovalsColumn>) => setSortInfo(value),
  };
  const columns = getPendingAprovalsColumns(columnProps);

  return (
    <AppContainer containerWidth="lg">
      <DashboardHeader cls="pt-6 pb-10">
        <p className="typo-h3 font-semibold text-center text-primary">
          Approvals
        </p>
        <p className="text-grey-darker text-center my-6">
          Take action to approve or reject an item after carefully reviewing the
          approval request details.
        </p>
      </DashboardHeader>
      <div className="snap-x snap-mandatory min-w-7xl max-w-7xl overflow-x-auto">
        <div className="min-w-780">
          <AppTableHeadings<PendingApprovalsColumn, API.QuorumOperation | null>
            columns={columns}
            borderCls={cx('border-grey-bright bg-white border-1 border', {
              'border-b-0': pendingOperations?.result?.length,
            })}
          />
          <AppTableRows<PendingApprovalsColumn, API.QuorumOperation | null>
            columns={columns}
            rows={pendingOperations?.result || []}
            rowPadding="px-5 md:px-7.5 py-4"
            emptyDataText={<SearchEmpty title="No pending operations found" />}
            cls={'cursor-pointer hover-bg-grey-brighter'}
            onRowClick={(f: API.QuorumOperation | null) =>
              showDialog(
                <QuorumApprovalComponent
                  operationDetails={f}
                  onClose={handleDismiss}
                />
              )
            }
          />
        </div>
      </div>
      <Paginator
        paginatedActivities={pendingOperations}
        onChanged={changed =>
          setPendingOperationsRequest({
            ...pendingOperationsRequest,
            page: changed?.page || pendingOperationsRequest.page,
          })
        }
      />
    </AppContainer>
  );
};
