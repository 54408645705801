import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { _DateFormat } from './_date-format';
// TODO: Docs
// Convention:
// "<Date*" components should not format Time (as opposed to "<DateTime*" or "<Time*" components)
// Note: "Numeric" stands for "01/01/2024" dates (as opposed to "1 Jan 2024" formatted dates, which contain month names / text)
export const DateNumeric = memo(({ short = false, ...rest }) => {
    return _jsx(_DateFormat, { ...rest, format: `dd/MM/${short ? 'yy' : 'yyyy'}` });
});
