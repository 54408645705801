import { API } from 'api';
import { Enriched } from '../../types';

export type PortfolioAccountsByType = {
  [key in API.AccountType]?: Enriched.ListAccountItem[];
};

export enum HoldingsTableColumnId {
  Asset = 'Asset',
  Quantity = 'Quantity',
  Price = 'Price',
  Value = 'Value',
  APY = 'APY',
  Change24h = 'Change24h',
  RateOfReturnMTD = 'RateOfReturnMTD',
  RateOfReturnYTD = 'RateOfReturnYTD',
  TotalReturn = 'TotalReturn',
  TotalInterestEarned = 'TotalInterestEarned',
  AccruedInterestThisWeek = 'AccruedInterestThisWeek',
  Action = 'Action',
}

export const HoldingsTableColumnTitle: Record<HoldingsTableColumnId, string> = {
  [HoldingsTableColumnId.Asset]: 'Asset',
  [HoldingsTableColumnId.Quantity]: 'Quantity',
  [HoldingsTableColumnId.Price]: 'Price',
  [HoldingsTableColumnId.Value]: 'Value',
  [HoldingsTableColumnId.APY]: 'APY %',
  [HoldingsTableColumnId.Change24h]: '24h change',
  [HoldingsTableColumnId.RateOfReturnMTD]: 'Rate of return (MTD)',
  [HoldingsTableColumnId.RateOfReturnYTD]: 'Rate of return (YTD)',
  [HoldingsTableColumnId.TotalReturn]: 'Total return',
  [HoldingsTableColumnId.TotalInterestEarned]: 'Total Interest earned',
  [HoldingsTableColumnId.AccruedInterestThisWeek]: 'Accrued Interest this week',
  [HoldingsTableColumnId.Action]: '',
};

export type HoldingsTableColumn = {
  title: string;
  id: HoldingsTableColumnId;
  sortable: boolean;
  sx: { width: number | string };
};
