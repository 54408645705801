import { Platform } from 'react-native';
export const shorthandToFontProps = (fontShort) => {
    const fontProps = {};
    const props = fontShort.split(' ');
    // TODO(Hadrien): validate all the tokens when generating to ensure that we dont pass a value that does not match that type
    fontProps['fontWeight'] = props[0];
    const sizes = props[1].split('/');
    fontProps['fontSize'] = parseInt(sizes[0]);
    fontProps['lineHeight'] = calculateLineHeight(parseInt(sizes[0]), sizes[1]);
    return fontProps;
};
export const calculateLineHeight = (fontSize, rawLineHeight) => {
    // TODO(Hadrien): Throw in Design System to avoid having calculations in the component,
    //    everything should be as pixels
    if (rawLineHeight.endsWith('%')) {
        return parseInt(((fontSize * parseFloat(rawLineHeight)) / 100).toString());
    }
    else {
        return fontSize;
    }
};
export const resetZindexOptionally = (s) => {
    const result = [...s];
    if (Platform.OS === 'web') {
        result.push({
            /**
             * Note:
             * RN defaults zIndex as number (see their) types
             * Amd also defaults zIndex on all elements to 0
             *
             * Issue created:
             * This meant in web-app the floating wrapper for action buttons fell in place within its relative parents z-index
             * and thus was under the stacking order of the row below it
             *
             * With this optional platrform reaset, we are able to provide a different experience and fix for the web app
             */
            zIndex: 'initial',
        });
    }
    return result;
};
