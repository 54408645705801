import { API } from 'api';
import { format as formatDate, startOfMonth } from 'date-fns';
import { EnrichedCurrencyInformation, EnrichedInvoice } from '../../types';
import { formatMoney } from '../format-money';

export function createEnrichedInvoice(
  _invoice: API.Invoice,
  currencies: EnrichedCurrencyInformation[]
): EnrichedInvoice {
  const amount = _invoice.amount ?? '0';

  const quoteCurrencyCode = _invoice.quoteAssetCode;
  const quoteCurrency = quoteCurrencyCode
    ? currencies.find(_currency => _currency.code === quoteCurrencyCode)
    : undefined;

  const formattedAmount = quoteCurrency
    ? formatMoney(
        amount,
        quoteCurrency.displayCode ?? quoteCurrency.code,
        quoteCurrency.decimals
      )
    : formatMoney(amount, 'USD');

  return {
    ..._invoice,
    amount: Number(amount),
    formattedAmount,
    formattedDueMonth: formatDate(
      startOfMonth(new Date(_invoice.dueDate)),
      'MMMM yyyy'
    ),
    isPayable: _invoice.status === API.InvoiceStatus.WaitingPayment,
    isPaid: _invoice.status === API.InvoiceStatus.Paid,
  };
}
