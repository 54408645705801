import { jsx as _jsx } from "react/jsx-runtime";
import { Platform, Text as RNText, } from 'react-native';
import { colors, createBox, createStyles } from '../theme';
const TextBox = createBox(RNText);
// Do not expose TextBase
// Use it to build other Texts
function TextBase({ color: colorName, em = false, style, ...rest }) {
    return (_jsx(TextBox, { ...rest, style: [style, em && $.em, !!colorName && { color: colors[colorName] }] }));
}
export function TextBody({ style, small, medium, large, ...rest }) {
    return (_jsx(TextBase, { ...rest, style: [
            $.body,
            small && $.bodySmall,
            medium && $.bodyMedium,
            large && $.bodyLarge,
            style,
        ] }));
}
export function TextCaption({ style, ...rest }) {
    return _jsx(TextBase, { ...rest, style: [$.caption, style] });
}
export function TextSubheading(props) {
    return _jsx(TextBody, { ...props, em: true });
}
const fontFamilies = {
    InterWeb: { fontFamily: 'Inter' },
    InterRegular: { fontFamily: 'Inter-Regular' },
    InterMedium: { fontFamily: 'Inter-Medium' },
    InterBold: { fontFamily: 'Inter-Bold' },
};
const fontNormalStyle = Platform.select({
    android: { ...fontFamilies.InterRegular },
    ios: { ...fontFamilies.InterRegular },
    web: {
        ...fontFamilies.InterWeb,
        fontWeight: '400',
    },
});
const fontBoldStyle = Platform.select({
    android: { ...fontFamilies.InterBold },
    ios: { ...fontFamilies.InterBold },
    web: {
        ...fontFamilies.InterWeb,
        fontWeight: '700',
    },
});
const fontStyles = {
    normal: fontNormalStyle,
    bold: fontBoldStyle,
};
const $ = createStyles({
    body: {
        ...fontStyles.normal,
        fontSize: 12,
        lineHeight: 16,
        color: 'onSurface',
    },
    bodySmall: {
        fontSize: 10,
        lineHeight: 14,
    },
    bodyMedium: {
        fontSize: 14,
        lineHeight: 20,
    },
    bodyLarge: {
        fontSize: 16,
        lineHeight: 24,
    },
    caption: {
        ...fontStyles.normal,
        fontSize: 10,
        lineHeight: 14,
        color: 'onSurface',
    },
    em: {
        ...fontStyles.bold,
        color: 'onSurfaceEm',
    },
});
