import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { DATE_FORMATS } from 'common';
import { format as formatDate } from 'date-fns';
import { TextCaption, TextBody } from '../lib';
export const _DateFormat = memo(({ format, small = false, value, ...rest }) => {
    // Vars
    const str = useMemo(() => {
        if (value === null || value === undefined) {
            return '-';
        }
        const dateValue = value instanceof Date ? value : new Date(value);
        try {
            return formatDate(dateValue, format);
        }
        catch (err) {
            // Should not happen !!
            // Error is thrown when date-fns `format` is unrecognized (fix it)
            // console.log('Date format error', err);
            return formatDate(dateValue, DATE_FORMATS.DEFAULT);
        }
    }, [format, value]);
    const TextEl = small ? TextCaption : TextBody;
    // Render
    return _jsx(TextEl, { ...rest, children: str });
});
