import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useMemo, useRef } from 'react';
import { Animated, Platform } from 'react-native';
import { formatters } from 'common';
import { TextCaption, TextBody } from '../lib';
const fadeOutConfig = {
    toValue: 0,
    duration: 300,
    useNativeDriver: Platform.OS !== 'web',
};
const fadeInConfig = {
    toValue: 1,
    duration: 300,
    useNativeDriver: Platform.OS !== 'web',
};
export const _AmountFormat = memo(({ animated = false, currencyCode, decimals, fiat, decipherPrecision, shorten = false, signed = false, small = false, unit = '', value, ...rest }) => {
    // Vars
    const opacity = useRef(new Animated.Value(1)).current;
    const isMounted = useRef(false);
    const amount = useMemo(() => {
        if (currencyCode) {
            return formatters.getCurrency(value, decimals, currencyCode, [], false, { average: shorten, fiat, decipherPrecision });
        }
        return formatters.getAmount(value, decimals, false, {
            average: shorten,
            trimMantissa: false,
        });
    }, [currencyCode, decimals, shorten, value, fiat, decipherPrecision]);
    const textContent = useMemo(() => {
        if (value === null) {
            return '-';
        }
        let _content = '';
        // Prefix
        if (signed && value > 0) {
            _content += '+';
        }
        // Amount (formatted)
        _content += shorten ? amount.toUpperCase() : amount;
        // Suffix
        _content += unit;
        return _content;
    }, [amount, shorten, signed, unit, value]);
    // Effects
    useEffect(() => {
        if (!isMounted.current) {
            // Skip animation on first render
            isMounted.current = true;
        }
        else if (animated) {
            // Fade OUT
            Animated.timing(opacity, fadeOutConfig).start(() => {
                // Fade IN
                Animated.timing(opacity, fadeInConfig).start();
            });
        }
    }, [animated, textContent]);
    // Render
    const TextEl = small ? TextCaption : TextBody;
    let colorName = undefined;
    if (value && signed) {
        colorName = value < 0 ? 'onSurfaceNegative' : 'onSurfacePositive';
    }
    const content = (_jsx(TextEl, { ...rest, color: colorName, children: textContent }));
    if (animated) {
        return _jsx(Animated.View, { style: { opacity }, children: content });
    }
    return content;
});
