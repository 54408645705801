import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Pressable } from 'react-native';
import { withShadowDOM } from '../hoc';
import { createStyles } from '../theme';
import { resetZindexOptionally } from '../utils';
export function TableCell({ children, onPress, renderInShadowDOM, width, align, }) {
    const computedStyles = resetZindexOptionally([
        $.container,
        { width },
        {
            justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
        },
    ]);
    const content = renderInShadowDOM
        ? withShadowDOM(() => _jsx(_Fragment, { children: children }))
        : children;
    return (_jsx(Pressable, { onPress: onPress, disabled: !onPress, style: computedStyles, children: content }));
}
const $ = createStyles({
    container: {
        row: 4,
        borderBottomWidth: 1,
        borderColor: 'outline',
        p: 's',
    },
});
