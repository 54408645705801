import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { API } from 'api';
import { getApiErrorMessage } from '../utils/get-api-error-message';
import { Injections, AdditionalOptionsXHR, ApiThunk } from './types';
import { DataModel } from './data-store';
import { runThunk, runThunkUnmanaged, runApi } from '../utils';
import { GlobalAppSettings } from '../types';
import {
  cryptoAddressesModel,
  CryptoAddressesModel,
} from './crypto-addresses-store';
import { BaseModel, createBaseModel } from './base-store';

type GetBankAccountsPayload =
  | undefined
  | ({
      request: API.GetBankAccountsRequest;
    } & AdditionalOptionsXHR);

export type GetBankAccountPayload = {
  request: API.GetBankAccountRequest;
} & AdditionalOptionsXHR;

export type CreateBankAccountOperationPayload = {
  request: API.CreateBankAccountRequest;
};

export interface SettingsModel extends BaseModel {
  // state
  tag: string | null;
  bankAccounts: API.BankAccount[] | null;
  bankAccount: API.BankAccount | null;
  bankAccountRequirements: API.BankAccountRequirementsOutput | null;
  notificationsSettings: API.NotificationsSettings | null;
  globalAppSettings: GlobalAppSettings | null;
  rewards: API.Reward[] | null;
  apiKeys: API.ApiKey[] | null;
  newlyCreatedApiKey: API.CreateApiKeyResponse | null;
  cryptoAddresses: CryptoAddressesModel;
  // computed
  hasNonVerifiedBankAccounts: Computed<SettingsModel, boolean>;
  // actions
  setBankAccounts: Action<SettingsModel, API.BankAccount[] | null>;
  setBankAccount: Action<SettingsModel, API.BankAccount | null>;
  setBankAccountRequirements: Action<
    SettingsModel,
    API.BankAccountRequirementsOutput | null
  >;
  setTag: Action<SettingsModel, string | null>;
  setNotificationsSettings: Action<SettingsModel, API.NotificationsSettings>;
  setGlobalAppSettings: Action<SettingsModel, GlobalAppSettings | null>;
  setRewards: Action<SettingsModel, API.Reward[] | null>;
  setApiKeys: Action<SettingsModel, API.ApiKey[]>;
  setNewlyCreatedApiKey: Action<SettingsModel, API.CreateApiKeyResponse>;
  // thunk
  getRewards: Thunk<
    SettingsModel,
    AdditionalOptionsXHR | undefined,
    Injections,
    DataModel
  >;
  getTag: Thunk<SettingsModel, AdditionalOptionsXHR, Injections, DataModel>;
  updatePassword: Thunk<
    SettingsModel,
    API.UpdatePasswordRequest,
    Injections,
    DataModel
  >;
  loadNotificationsSettings: Thunk<
    SettingsModel,
    unknown,
    Injections,
    DataModel
  >;
  updateNotificationsSettings: Thunk<
    SettingsModel,
    API.NotificationsSettings,
    Injections,
    DataModel
  >;
  getBankAccounts: Thunk<
    SettingsModel,
    GetBankAccountsPayload,
    Injections,
    DataModel
  >;
  getBankAccount: Thunk<
    SettingsModel,
    GetBankAccountPayload,
    Injections,
    DataModel
  >;
  getBankAccountRequirements: ApiThunk<
    SettingsModel,
    API.BankAccountRequirementsInput,
    API.BankAccountRequirementsOutput
  >;
  getAccountSettings: Thunk<
    SettingsModel,
    AdditionalOptionsXHR,
    Injections,
    DataModel,
    Promise<API.AccountSettingsResponse | null>
  >;
  createBankAccount: Thunk<
    SettingsModel,
    API.CreateBankAccountRequest,
    Injections,
    DataModel,
    Promise<API.BankAccount | null>
  >;
  createBankAccountOperation: Thunk<
    SettingsModel,
    CreateBankAccountOperationPayload,
    Injections,
    DataModel
  >;
  verifyBankAccount: Thunk<
    SettingsModel,
    API.VerifyBankAccountRequest,
    Injections,
    DataModel
  >;
  resendBankAccountVerificationCode: Thunk<
    SettingsModel,
    API.ResendBankAccountVerificationCodeRequest,
    Injections,
    DataModel
  >;
  deleteBankAccount: Thunk<
    SettingsModel,
    API.DeleteBankAccountRequest,
    Injections,
    DataModel,
    Promise<boolean>
  >;
  deleteBankAccountOperation: Thunk<
    SettingsModel,
    API.DeleteBankAccountRequest,
    Injections,
    DataModel
  >;
  getApiKeys: Thunk<SettingsModel, undefined, Injections, DataModel>;
  createApiKey: Thunk<
    SettingsModel,
    API.CreateApiKeyRequest,
    Injections,
    DataModel,
    Promise<API.CreateApiKeyResponse | null>
  >;
  deleteApiKey: Thunk<
    SettingsModel,
    API.DeleteApiKeyRequest,
    Injections,
    DataModel
  >;
  getFees: ApiThunk<
    SettingsModel,
    API.DownloadOrganizationDocumentRequest,
    API.GetOrganizationDocumentResponse
  >;
}

export const settingsModel: SettingsModel = {
  ...createBaseModel(),

  tag: null,
  bankAccounts: null,
  bankAccount: null,
  bankAccountRequirements: null,
  notificationsSettings: null,
  globalAppSettings: null,
  rewards: null,
  apiKeys: null,
  newlyCreatedApiKey: null,
  cryptoAddresses: cryptoAddressesModel,
  // computed
  hasNonVerifiedBankAccounts: computed(
    [s => s.bankAccounts ?? []],
    bankAccounts =>
      bankAccounts.some(
        ({ status }) => status === API.CustomerBankAccountStatus.Verifying
      )
  ),
  // actions
  setBankAccounts: action((state, payload) => {
    state.bankAccounts = payload;
  }),
  setBankAccount: action((state, payload) => {
    state.bankAccount = payload;
  }),
  setBankAccountRequirements: action((state, payload) => {
    state.bankAccountRequirements = payload;
  }),
  setTag: action((state, payload) => {
    state.tag = payload;
  }),
  setNotificationsSettings: action((state, payload) => {
    state.notificationsSettings = payload;
  }),
  setGlobalAppSettings: action((state, payload) => {
    state.globalAppSettings = payload;
  }),
  setRewards: action((state, payload) => {
    state.rewards = payload;
  }),
  setApiKeys: action((state, payload) => {
    state.apiKeys = payload;
  }),
  setNewlyCreatedApiKey: action((state, payload) => {
    state.newlyCreatedApiKey = payload;
  }),
  // thunk
  getRewards: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        if (!payload?.isBackgroundXHR) {
          storeActions.setBusy(true);
        }
        const { isSuccessful, result, errorMessage } =
          await injections.apiClient.getRewards();

        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return;
        }
        actions.setRewards(result?.rewards || []);
        storeActions.setBusy(false);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
      }
    }
  ),
  getTag: thunk(async (actions, payload, { injections, getStoreActions }) => {
    const storeActions = getStoreActions();
    try {
      if (!payload.isBackgroundXHR) {
        storeActions.setBusy(true);
      }
      const { isSuccessful, errorMessage, result } =
        await injections.apiClient.getTag();
      if (!isSuccessful) {
        storeActions.setError(errorMessage);
        storeActions.setBusy(false);
        return;
      }
      actions.setTag(result?.tag || null);
      storeActions.setBusy(false);
    } catch (error) {
      const message = getApiErrorMessage(error);
      storeActions.setError(message);
      storeActions.setBusy(false);
    }
  }),
  updatePassword: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);

        const { isSuccessful, errorMessage } =
          await injections.apiClient.updatePassword(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return Promise.resolve(false);
        }
        storeActions.setBusy(false);
        return Promise.resolve(true);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
      }
      return Promise.resolve(false);
    }
  ),
  getBankAccounts: thunk(
    async (actions, payload = { request: {} }, helpers) => {
      const setBusy = !payload.isBackgroundXHR;
      runThunk<API.BankAccountArrayApiResponse>(
        helpers,
        {
          execute: async () =>
            await helpers.injections.apiClient.getBankAccounts(payload.request),
          onSucccess: response => {
            actions.setBankAccounts(response.result);
          },
        },
        setBusy,
        true,
        helpers.getStoreState().additionalHeaders
      );
    }
  ),
  getBankAccount: thunk(async (actions, payload, helpers) => {
    const setBusy = !payload.isBackgroundXHR;
    runThunk<API.BankAccountApiResponse>(
      helpers,
      {
        execute: async () =>
          await helpers.injections.apiClient.getBankAccount(payload.request),
        onSucccess: response => {
          actions.setBankAccount(response.result);
        },
        onError: () => {
          if (payload.throwOnError) {
            throw new Error();
          }
        },
      },
      setBusy,
      true,
      helpers.getStoreState().additionalHeaders
    );
  }),
  getBankAccountRequirements: thunk(async (actions, payload, helpers) => {
    const storeState = helpers.getStoreState();
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.getBankAccountRequirements(payload);
      },
      result => {
        actions.setBankAccountRequirements(result);
      },
      {
        'x-account-id': storeState.user.decodedTokenAccountId,
      }
    );
  }),
  getAccountSettings: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        if (!payload.isBackgroundXHR) {
          storeActions.setBusy(true);
        }

        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.getAccountSettings();
        if (!isSuccessful || !result) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return null;
        }

        const settings: GlobalAppSettings = {
          // about: 2fa
          is2FaEnabled: !!result.is2FaEnabled,
          enforce2FA: !!result.enforce2FA,
          // about: user related
          userType: result.userType,
          proofOfAddressStatus: result.proofOfAddressStatus,
          kycTier: result.kycTier,
          // about: user's org
          organizationId: result.organizationId,
          organizationName: result.organizationName,
          organizationIsCorporate: result.organizationIsCorporate,
          relationshipManagerName: result.relationshipManagerName,
          relationshipManagerEmail: result.relationshipManagerEmail,
          relationshipManagerEmailLink: `mailto:${result.relationshipManagerEmail}`,
          relationshipManagerPhoneNumber: result.relationshipManagerPhoneNumber,
          relationshipManagerTelegramId: result.relationshipManagerTelegramId,
          whiteLabelLiteSettings: result.whiteLabelLiteSettings,
          // about: users - org level permissions
          allowStabletag: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
          allowFeatureRequest: result.allowFeatureRequest,
          allowApiKeys: result.allowApiKeys,
          allowReferralRewards: result.allowReferralRewards,
          // special route - fund ADMIN - userType: ClientReadWrite
          accountName: result.accountName,
          tradingDeskContactEmail: 'tradingdesk@stablehouse.com',
          tradingDeskContactEmailLink: 'mailto:tradingdesk@stablehouse.com',
          tradingDeskContactName: 'Arnold Griesel',
          tradingDeskContactPhone: '+1 646 934 6723',
          tradingDeskContactPhoneLink: 'tel:+13025951396',
          showRelationshipManagerInfo:
            !!result.relationshipManagerName ||
            !!result.relationshipManagerEmail,
          showTradingDeskInfo:
            !!result.relationshipManagerName ||
            !!result.relationshipManagerEmail,
        };
        actions.setGlobalAppSettings(settings);
        storeActions.setBusy(false);
        return result;
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
        if (payload.throwOnError) {
          throw error;
        }
        return null;
      }
    }
  ),
  createBankAccount: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setError(null);
        storeActions.setBusy(true);
        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.createBankAccount(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return Promise.resolve(null);
        }
        storeActions.setBusy(false);
        return Promise.resolve(result);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
        return Promise.resolve(null);
      }
    }
  ),
  createBankAccountOperation: thunk(async (_actions, payload, helpers) => {
    let isSuccessful = false;
    await runThunkUnmanaged(
      helpers,
      {
        execute: async () => {
          helpers.getStoreActions().setError(null);
          const result =
            await helpers.injections.apiClient.createBankAccountOperation(
              payload.request
            );
          isSuccessful = result.isSuccessful;
          return { ...result };
        },
      },
      true,
      true,
      helpers.getStoreState().additionalHeaders
    );
    return isSuccessful;
  }),
  verifyBankAccount: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const { isSuccessful, errorMessage } =
          await injections.apiClient.verifyBankAccount(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return;
        }
        storeActions.setBusy(false);
        return Promise.resolve(true);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
        return Promise.resolve(false);
      }
    }
  ),
  resendBankAccountVerificationCode: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const { isSuccessful, errorMessage } =
          await injections.apiClient.resendBankAccountVerificationCode(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return Promise.resolve(false);
        }
        storeActions.setBusy(false);
        return Promise.resolve(true);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
        return Promise.resolve(false);
      }
    }
  ),
  deleteBankAccount: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const { isSuccessful, errorMessage } =
          await injections.apiClient.deleteBankAccount(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);

          return false;
        }
        storeActions.setBusy(false);

        return true;
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);

        return false;
      }
    }
  ),

  deleteBankAccountOperation: thunk(async (_actions, payload, helpers) => {
    let isSuccessful = false;
    await runThunkUnmanaged(
      helpers,
      {
        execute: async () => {
          const result =
            await helpers.injections.apiClient.deleteBankAccountOperation(
              payload
            );
          isSuccessful = result.isSuccessful;
          return { ...result };
        },
      },
      true,
      true,
      helpers.getStoreState().additionalHeaders
    );
    return isSuccessful;
  }),
  loadNotificationsSettings: thunk(
    async (actions, _payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.getNotificationsSettings();
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          throw new Error(errorMessage!);
        }
        actions.setNotificationsSettings(result!);
        storeActions.setBusy(false);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        throw error;
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),
  updateNotificationsSettings: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const { isSuccessful, errorMessage } =
          await injections.apiClient.setNotificationsSettings(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return Promise.resolve(false);
        }
        storeActions.setBusy(false);
        await actions.loadNotificationsSettings({});
        return {};
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        throw error;
      } finally {
        storeActions.setBusy(false);
      }
    }
  ),
  getApiKeys: thunk(
    async (actions, _payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);

        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.getApiKeys();
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return;
        }

        actions.setApiKeys(result!);
        storeActions.setBusy(false);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
      }
    }
  ),
  createApiKey: thunk(
    async (actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);

        const { isSuccessful, errorMessage, result } =
          await injections.apiClient.createApiKey(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return null;
        }
        actions.setNewlyCreatedApiKey(result!);
        storeActions.setBusy(false);
        return result;
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
        return null;
      }
    }
  ),
  deleteApiKey: thunk(
    async (_actions, payload, { injections, getStoreActions }) => {
      const storeActions = getStoreActions();
      try {
        storeActions.setBusy(true);
        const { isSuccessful, errorMessage } =
          await injections.apiClient.deleteApiKey(payload);
        if (!isSuccessful) {
          storeActions.setError(errorMessage);
          storeActions.setBusy(false);
          return;
        }
        storeActions.setBusy(false);
        return Promise.resolve(true);
      } catch (error) {
        const message = getApiErrorMessage(error);
        storeActions.setError(message);
        storeActions.setBusy(false);
        return Promise.resolve(false);
      }
    }
  ),
  getFees: thunk((actions, payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.downloadDocumentType(payload);
      },
      undefined,
      {
        'x-account-id': helpers.getStoreState().user.decodedTokenAccountId,
      }
    );
  }),
};
