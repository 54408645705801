import React, { FC, useCallback } from 'react';
import { NUMBER_FORMAT } from '../../constants';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputProps, InputWrapper } from '../form/text-input';
import cx from 'classnames';

export type NumberInputProps = Omit<
  NumberFormatProps,
  'onChange' | 'onValueChange'
> & {
  onChange?: (value: string) => void;
};

export const NumberInput: FC<NumberInputProps & InputProps> = ({
  errored = false,
  leftAddon,
  clsWrapper,
  rightAddon,
  clearInput,
  leftAddonCls,
  rightAddonCls,
  leftAddonBorder,
  handleClearInput,
  leftAddonDisabled,
  onChange,
  ...rest
}) => {
  /**
   * Handlers
   */
  const handleChange = useCallback(
    ({ value }) => {
      onChange?.(value);
    },
    [onChange]
  );

  /**
   * DOM
   * */
  const isNumeric = rest.decimalScale && rest.decimalScale === 0;

  return (
    <InputWrapper
      errored={errored}
      value={rest.value}
      leftAddon={leftAddon}
      clsWrapper={clsWrapper}
      rightAddon={rightAddon}
      clearInput={clearInput}
      leftAddonCls={leftAddonCls}
      rightAddonCls={rightAddonCls}
      leftAddonBorder={leftAddonBorder}
      handleClearInput={handleClearInput}
      displayType={rest.displayType || 'input'}
      leftAddonDisabled={leftAddonDisabled || Boolean(rest.disabled)}
    >
      {(inputCls, disabled, node, setLeftAddonBorderSelected) => (
        <NumberFormat
          {...rest}
          thousandSeparator={NUMBER_FORMAT.THOUSANDS_SEPARATOR}
          decimalSeparator={NUMBER_FORMAT.DECIMAL_SEPARATOR}
          id={rest.name}
          getInputRef={node}
          data-testid={rest.name}
          disabled={rest?.disabled || disabled}
          inputMode={isNumeric ? 'numeric' : 'decimal'}
          className={cx({ [inputCls]: rest?.displayType !== 'text' })}
          onFocus={() => {
            if (!setLeftAddonBorderSelected) return;
            setLeftAddonBorderSelected(true);
          }}
          onBlur={() => {
            if (!setLeftAddonBorderSelected) return;
            setLeftAddonBorderSelected(false);
          }}
          onValueChange={handleChange}
        />
      )}
    </InputWrapper>
  );
};
