import {
  AppIcon,
  Enriched,
  EnrichedAccountDetailAsset,
  EnrichedAssetHolding,
  EnrichedAssetHoldings,
} from 'common';
import { FC, useCallback, useState } from 'react';
import { DataStore } from '../../store';
import { AppRoundButton } from '../app-round-button';
import cx from 'classnames';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { DashboardRowSelect } from '../dashboard/types';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { DASHBOARD_SELECTED_DIALOG_TYPE } from '~/store/types';
import { AppLoader } from '~/components/app-loader';

interface Props {
  asset: EnrichedAccountDetailAsset | EnrichedAssetHolding;
  onActionClick: DashboardRowSelect;
  menuItemCls?: string;
}

const defaultsButtonProps = {
  iconCls: 'bg-transparent circle-btn',
  cls: 'py-2.5 px-3.5 hover-bg-grey-brighter',
  iconInteractive: false,
};
const iconProps = {
  size: 25,
  cls: 'bg-transparent cursor-pointer',
};

export const getAllRowButtons = <T,>(
  onActionClick: (
    type: DASHBOARD_SELECTED_DIALOG_TYPE | null,
    asset: T
  ) => void,
  asset: T
) => ({
  view: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="visibility-show"
          {...iconProps}
          size={19}
          bg="transparent"
        />
      }
      text="View"
      key="view-account"
      onClick={() => {
        onActionClick(null, asset);
      }}
    />
  ),
  buy: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="workflow-buy"
          {...iconProps}
          bg="transparent"
          size="md"
        />
      }
      text="Buy"
      key="buy"
      onClick={() => {
        onActionClick('workflow-buy', asset);
      }}
    />
  ),
  sell: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="workflow-sell"
          {...iconProps}
          bg="transparent"
          size="md"
        />
      }
      text="Sell"
      key="sell"
      onClick={() => {
        onActionClick('workflow-sell', asset);
      }}
    />
  ),
  send: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="workflow-send"
          {...iconProps}
          bg="transparent"
          size="md"
        />
      }
      text="Send"
      key="send"
      onClick={() => onActionClick('workflow-send', asset)}
    />
  ),
  receive: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="workflow-receive"
          {...iconProps}
          bg="transparent"
          size="md"
        />
      }
      text="Receive"
      key="receive"
      onClick={() => {
        onActionClick('workflow-receive', asset);
      }}
    />
  ),
  addCash: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="workflow-add-cash"
          {...iconProps}
          bg="transparent"
          size="md"
        />
      }
      text="Add cash"
      key="add cash"
      onClick={() => {
        onActionClick('workflow-add-cash', asset);
      }}
    />
  ),
  withdrawCash: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="workflow-withdraw-cash"
          {...iconProps}
          bg="transparent"
          size="md"
        />
      }
      text="Withdraw cash"
      key="withdraw cash"
      onClick={() => onActionClick('workflow-withdraw-cash', asset)}
    />
  ),
  transfer: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="workflow-transfer"
          {...iconProps}
          bg="transparent"
          size="md"
        />
      }
      text="Transfer"
      key="transfer"
      onClick={() => onActionClick('workflow-transfer', asset)}
    />
  ),
  downloadStatement: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon icon="download" {...iconProps} size={19} bg="transparent" />
      }
      text="Download latest statement"
      key="DOWNLOAD_LATEST_STATEMENT"
      onClick={() => {
        onActionClick('DOWNLOAD_LATEST_STATEMENT', asset);
      }}
    />
  ),
  viewAllStatements: (
    <AppRoundButton
      {...defaultsButtonProps}
      icon={
        <AppIcon
          icon="visibility-show"
          {...iconProps}
          size={19}
          bg="transparent"
        />
      }
      text="View all statements"
      key="VIEW_STATEMENTS"
      onClick={() => {
        onActionClick('VIEW_STATEMENTS', asset);
      }}
    />
  ),
});

const getButtons = (
  asset: EnrichedAccountDetailAsset | EnrichedAssetHolding,
  accounts: Enriched.ListAccountItem[],
  onActionClick: DashboardRowSelect,
  isClient: boolean
) => {
  const buttons: JSX.Element[] = [];
  const allButtons = getAllRowButtons(onActionClick, asset);

  if (!asset.currency.isAssetOfTypeFund) {
    buttons.push(allButtons.view);
  }

  if (asset.canTrade) {
    buttons.push(allButtons.buy);
    buttons.push(allButtons.sell);
  }

  if (
    (asset.canSendCrypto || asset.canStabletagTransfer) &&
    asset.hasBalance &&
    !asset.currency.isAssetOfTypeFiat &&
    !asset.currency.isAssetOfTypeFund
  ) {
    buttons.push(allButtons.send);
  }

  if (
    asset.canReceiveCrypto &&
    !isClient &&
    !asset.currency.isAssetOfTypeFiat &&
    !asset.currency.isAssetOfTypeFund
  ) {
    buttons.push(allButtons.receive);
  }

  if (asset.canReceiveCash && !!asset.currency.isAssetOfTypeFiat) {
    buttons.push(allButtons.addCash);
  }

  if (
    asset.canSendCash &&
    asset.hasBalance &&
    !!asset.currency.isAssetOfTypeFiat
  ) {
    buttons.push(allButtons.withdrawCash);
  }

  if (asset.canSendAccountTransfer && !!(accounts?.length > 1)) {
    buttons.push(allButtons.transfer);
  }

  if (asset.currency.isAssetOfTypeFund) {
    buttons.push(allButtons.downloadStatement);
  }
  if (asset.currency.isAssetOfTypeFund) {
    buttons.push(allButtons.viewAllStatements);
  }

  return buttons;
};

export const WorkflowRowButtons: FC<Props> = ({
  asset,
  onActionClick,
  menuItemCls = '',
}) => {
  /**
   * Store
   */
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const isClient = DataStore.useStoreState(s => s.user.isClient);

  /**
   * State
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * DOM
   */
  const buttons = getButtons(asset, accounts, onActionClick, !!isClient);
  if (!buttons || !buttons.length) {
    return null;
  }
  return (
    <FloatingWrapper
      isFund={asset.currency.isAssetOfTypeFund}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      menuItemCls={menuItemCls}
    >
      <>{buttons}</>
    </FloatingWrapper>
  );
};

interface PortfolioProps {
  assetCode?: string;
  menuItemCls?: string;
  onWorkflowSelected: (
    type: DASHBOARD_SELECTED_DIALOG_TYPE,
    asset: EnrichedAssetHoldings
  ) => void;
  capabilities: {
    canView?: boolean;
    canBuy?: boolean;
    canSell?: boolean;
    canSend?: boolean;
    canReceive?: boolean;
    canAddCash?: boolean;
    canWithdrawCash?: boolean;
    canTransfer?: boolean;
  };
  placement: 'top' | 'bottom';
}

const getPortfolioButtons = (
  asset: EnrichedAssetHoldings,
  capabilities: PortfolioProps['capabilities'],
  accounts: Enriched.ListAccountItem[],
  onWorkflowSelected: (
    type: DASHBOARD_SELECTED_DIALOG_TYPE,
    asset: EnrichedAssetHoldings
  ) => void
) => {
  const buttons: JSX.Element[] = [];
  const allButtons = getAllRowButtons(onWorkflowSelected, asset);
  const isNotfund = !asset.currency.isAssetOfTypeFund;
  const isNotFiat = !asset.currency.isAssetOfTypeFiat;

  if (isNotfund && capabilities.canView) {
    buttons.push(allButtons.view);
  }

  if (capabilities.canBuy) {
    buttons.push(allButtons.buy);
  }
  if (capabilities.canSell) {
    buttons.push(allButtons.sell);
  }

  if (capabilities.canSend && asset.hasBalance && isNotFiat && isNotfund) {
    buttons.push(allButtons.send);
  }

  if (capabilities.canReceive && isNotFiat && isNotfund) {
    buttons.push(allButtons.receive);
  }

  if (capabilities.canAddCash && !isNotFiat) {
    buttons.push(allButtons.addCash);
  }

  if (capabilities.canWithdrawCash && asset.hasBalance && !isNotFiat) {
    buttons.push(allButtons.withdrawCash);
  }

  if (capabilities.canTransfer /* && !!(accounts?.length > 1)*/) {
    buttons.push(allButtons.transfer);
  }

  if (asset.currency.isAssetOfTypeFund) {
    buttons.push(allButtons.downloadStatement);
  }
  if (asset.currency.isAssetOfTypeFund) {
    buttons.push(allButtons.viewAllStatements);
  }

  return buttons;
};

export const WorkflowPortfolioRowButtons: FC<PortfolioProps> = ({
  assetCode,
  onWorkflowSelected,
  menuItemCls = '',
  capabilities,
  placement,
}) => {
  /**
   * Store
   */
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const busy = DataStore.useStoreState(s => s.busy);
  const assetHoldings = DataStore.useStoreState(s => s.portfolio.assetHoldings);
  const { getAssetHoldings } = DataStore.useStoreActions(_ => ({
    getAssetHoldings: _.portfolio.getAssetHoldings,
  }));

  /**
   * State
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Hooks
   */
  const onOpenChange = useCallback(
    open => {
      if (open && assetCode) {
        getAssetHoldings({ currencyCode: assetCode });
      }
      setIsOpen(open);
    },
    [assetCode]
  );

  /**
   * DOM
   */
  const buttons = assetHoldings
    ? getPortfolioButtons(
        assetHoldings,
        capabilities,
        accounts,
        onWorkflowSelected
      )
    : null;

  if ((!buttons || !buttons.length) && !assetCode) {
    return null;
  }
  return (
    <FloatingWrapper
      isFund={!!assetHoldings?.currency.isAssetOfTypeFund}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      menuItemCls={menuItemCls}
      placement={placement}
    >
      <>
        {busy && (
          <div className="h-12 transform scale-50">
            <AppLoader bgColor="transparent" />
          </div>
        )}
        {!busy && buttons}
      </>
    </FloatingWrapper>
  );
};

interface FloatingWrapperProps {
  isFund: boolean | null;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
  menuItemCls: string;
  children: JSX.Element;
  placement?: 'top' | 'bottom';
}

const FloatingWrapper = ({
  isFund,
  isOpen,
  onOpenChange,
  menuItemCls,
  children,
  placement,
}: FloatingWrapperProps) => {
  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange,
    middleware: [
      offset({
        mainAxis: 5,
        crossAxis: isFund ? -110 : -70,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);
  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        className="rounded-full p-1"
      >
        <DotsVerticalIcon
          className={cx('w-5 h-5', {
            'text-primary': isOpen,
            'text-gray-400': !isOpen,
          })}
        />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className={cx(
              isFund ? 'w-64' : 'w-44',
              'bg-white rounded-md shadow-2xl focus:outline-none border z-50 pointer-events-auto overflow-hidden',
              menuItemCls
            )}
          >
            {children}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
