import { nanoid } from 'nanoid';

const reAlphaTitleCase = /[A-Z][a-z]+/g;

const reAlphaNumTitlCase = /(([A-Z][a-z]+)|[0-9][a-z0-9]+)/g;

// converts WaitingForWithdrawal into Waiting For Withdrawal
export const humanize = (input: string) => {
  const matches = input.match(reAlphaTitleCase);

  if (matches) {
    return matches.join(' ');
  }

  return input;
};

// converts WaitingForWithdrawal into Waiting for withdrawal
export const humanizeLower = (input: string) => {
  const matches = input.match(reAlphaTitleCase);

  if (matches) {
    return matches
      .map((i, index) => (index === 0 ? i : i.toLowerCase()))
      .join(' ');
  }

  return input;
};

export const humanizeTradingValue = (input: string) => {
  const matches = input.match(reAlphaNumTitlCase);

  if (matches) {
    return matches
      .map((i, index) => (index === 0 ? i : i.toLowerCase()))
      .join(' ');
  }

  return input;
};

export function randomString(length: number) {
  return nanoid(length);
}
