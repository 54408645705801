import { jsx as _jsx } from "react/jsx-runtime";
import { TextCaption, Col } from '../lib';
import { createStyles } from '../theme';
export const Label = ({ small = false, title }) => {
    return (_jsx(Col.C, { style: [$.container, small && $.containerSmall], children: _jsx(TextCaption, { em: true, style: $.label, children: title }) }));
};
const $ = createStyles({
    container: {
        backgroundColor: 'infoSurface',
        borderRadius: 999,
        p: 'xs',
    },
    containerSmall: {
        py: 'xxs',
        paddingHorizontal: 6,
    },
    label: {
        color: 'onInfoSurface',
    },
});
