import cx from 'classnames';
import { AppIcon } from 'common';
import { CSSProperties } from 'react';
import { SortInfo } from '~/store/types';

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortProps<T> {
  sort: SortInfo<T> | null;
  onSort: (params: SortInfo<T>) => void;
}

export type Cell<R> = (row: R) => string | JSX.Element | null;

export interface Props<C, R> extends SortProps<C> {
  name: C;
  type?: 'row' | 'column';
  sx?: CSSProperties;
  cls?: string;
  showSort?: boolean;
  heading?: string | JSX.Element;
  row?: R;
  cell?: Cell<R>;
  padding?: string;
  justify?: string;
  hideShowHeaderCls?: string;
  stopEventPropogation?: boolean;
  placeholder?: string;
}

export const Cell = <C, R>({
  sort,
  onSort,
  name,
  sx = undefined,
  cls = '',
  heading = '',
  showSort = true,
  type,
  cell,
  row,
  padding = 'p-0',
  justify = 'items-center',
  hideShowHeaderCls,
  stopEventPropogation = false,
  placeholder,
}: Props<C, R>) => {
  /**
   * DOM
   */

  const cellCls = cx(
    'flex items-center text-primary',
    justify,
    padding,
    hideShowHeaderCls,
    cls
  );

  if (type === 'column') {
    const sortIcon =
      sort && sort.direction === SortDirection.DESC ? (
        <AppIcon
          icon="arrow-dropdown"
          size={10}
          height={12}
          bg="bg-transparent"
        />
      ) : (
        <AppIcon
          icon="arrow-dropup"
          size={10}
          height={12}
          bg="bg-transparent"
        />
      );

    const handleSortClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      e.preventDefault();
      if (showSort) {
        onSort({
          column: name,
          direction:
            !sort || sort.direction === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        });
      }
    };

    return (
      <div style={sx} className={cellCls}>
        <div
          onClick={handleSortClick}
          className={cx('flex items-center gap-x-1', {
            'cursor-pointer': showSort,
          })}
        >
          <span>{heading}</span>

          {showSort && sort?.column === name && (
            <button className="w-3">{sortIcon}</button>
          )}
        </div>
      </div>
    );
  }

  if (!cell || !row) {
    return null;
  }
  const cellData = cell(row);
  return (
    <div
      style={sx}
      className={cellCls}
      {...(stopEventPropogation && {
        onClick: e => {
          e.stopPropagation();
          e.preventDefault();
        },
      })}
    >
      {cellData || <>{placeholder}</>}
    </div>
  );
};
