import { API } from 'api';
import {
  AppContainer,
  AppIcon,
  DEFAULTS,
  TextInput,
  useComputedConfig,
} from 'common';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../routes';
import { DataStore } from '../../store';
import { AppTableHeadings } from '../../components/app-table/headings';
import { AppTableRows } from '../../components/app-table/rows';
import {
  ApexFundColumn,
  getApexFundColumns,
} from '../../components/dashboard/apex-quorum-tables/columns';
import { SortInfo } from '~/store/types';
import { Widgets } from '~/components/widgets';
import cx from 'classnames';
import { SearchEmpty } from '@xbto/universal-components';

export const Default: FC = () => {
  /**
   * Store
   */
  const { getFundAccounts } = DataStore.useStoreActions(_ => ({
    getFundAccounts: _.admin.getFundAccounts,
  }));

  const busy = DataStore.useStoreState(s => s.busy);
  const fundAccounts = DataStore.useStoreState(s => s.admin.fundAccounts);
  const showRelationshipManagerInfo = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.showRelationshipManagerInfo
  );
  const hasAccountOfTypeCustody = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeCustody
  );
  const hasAccountOfTypeTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeTrading
  );
  const hasAccountOfTypeProTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeProTrading
  );

  /**
   * State
   */
  const [sortInfo, setSortInfo] = useState<SortInfo<ApexFundColumn> | null>(
    null
  );
  const [search, setSearch] = useState<string>('');

  /**
   * Hooks
   */
  const { tenant } = useComputedConfig();

  useEffect(() => {
    (async () => {
      await getFundAccounts({
        page: DEFAULTS.PAGE,
        pageSize: DEFAULTS.PAGE_SIZE,
        search,
      });
    })();
  }, [search]);
  /**
   * DOM
   */
  const columnProps = {
    sort: sortInfo,
    onSort: (value: SortInfo<ApexFundColumn>) => setSortInfo(value),
  };
  const columns = getApexFundColumns(columnProps);
  return (
    <AppContainer fullHeight containerWidth="lg">
      {/* busy  */}
      <header className="flex flex-col pt-6 w-full">
        <p className="text-primary typo-h3 font-semibold text-center">
          Fund accounts
        </p>
        <p className="text-grey-darker text-center my-6">
          Search the fund by name and view their account
        </p>
      </header>
      <AppContainer
        cls="py-8"
        containerWidth="lg"
        rightAddOn={
          <div className="flex flex-col gap-6">
            {/*markets widget*/}
            <Widgets.ExploreMarket />
            {tenant !== 'Apex' && <Widgets.WidgetPortfolioUserActions />}
          </div>
        }
      >
        <AppTableHeadings<ApexFundColumn, API.ApexFund | null>
          columns={columns}
          title={
            <div className="flex flex-col justify-between pb-7 gap-y-3 px-4 md:px-8">
              <p className="font-bold text-primary">Fund accounts</p>
              <TextInput
                type={'text'}
                minLength={2}
                value={search}
                placeholder={`Fund accounts search`}
                handleClearInput={() => setSearch('')}
                leftAddon={
                  <AppIcon icon="search-input" bg="bg-transparent" size={18} />
                }
                onChange={e => {
                  setSearch(e.target.value || '');
                }}
              />
            </div>
          }
          containerCls="pt-6"
          borderCls={cx('border-grey-bright bg-white border-b-0 border', {
            'border-b-0': fundAccounts?.result?.length,
          })}
        />
        <AppTableRows<ApexFundColumn, API.ApexFund | null>
          as={Link}
          to={row =>
            !row
              ? ''
              : APP_ROUTES.AUTH_ADMIN_FUND_ACCOUNT_OVERVIEW +
                `/${row.accountId}/accounts`
          }
          columns={columns}
          rows={busy ? undefined : fundAccounts?.result}
          emptyDataText={<SearchEmpty title="No matching fund account(s)" />}
          cls={'cursor-pointer hover-bg-grey-brighter'}
        />
        <div className="flex flex-col gap-y-6 py-8">
          <Widgets.WidgetPortfolioHome
            showAccountTypeInfo
            title="Discover more"
            hasAccountOfTypeCustody={hasAccountOfTypeCustody}
            hasAccountOfTypeTrading={hasAccountOfTypeTrading}
            hasAccountOfTypeProTrading={hasAccountOfTypeProTrading}
            hasRelationshipManager={showRelationshipManagerInfo}
          />
          <Widgets.WidgetPortfolioHome title="Help" showHelpInfo />
        </div>
      </AppContainer>
    </AppContainer>
  );
};
