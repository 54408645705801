import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { AmountChangePercent, AmountIssuance, AmountPrice, } from '../amount-format';
import { DateMaturity } from '../date-format';
import { TextBody } from '../lib';
import { TableCell, TableRow } from '../table';
import { TableCellAsset } from './table-cell-asset';
import { BOND_HEADERS as HEADERS } from './market-table-header';
export const MarketTableItemBond = memo(({ actions, currencyCode, data, onPress, ...rest }) => {
    // Handlers
    const handlePress = useCallback(() => {
        onPress?.(currencyCode);
    }, [currencyCode, onPress]);
    const cellProps = {
        align: 'right',
        onPress: onPress ? handlePress : undefined,
    };
    // Render
    return (_jsxs(TableRow, { children: [_jsx(TableCellAsset, { ...rest, onPress: onPress ? handlePress : undefined, width: HEADERS[0]?.width }), _jsx(TableCell, { width: HEADERS[1]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountChangePercent, { value: data.yieldPercent, em: true }) }), _jsx(TableCell, { width: HEADERS[2]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountChangePercent, { value: data.couponPercent, em: true }) }), _jsx(TableCell, { width: HEADERS[3]?.width, ...cellProps, children: _jsx(AmountPrice, { color: "onSurfaceEm", value: data.price, em: true }) }), _jsx(TableCell, { width: HEADERS[4]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(DateMaturity, { color: "onSurfaceEm", value: data.maturity, em: true }) }), _jsx(TableCell, { width: HEADERS[5]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(TextBody, { color: "onSurfaceEm", em: true, children: data.paymentFrequency }) }), _jsx(TableCell, { width: HEADERS[6]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountIssuance, { color: "onSurfaceEm", currency: data.issuanceAmountCurrency, value: data.issuanceAmount, em: true }) }), _jsx(TableCell, { width: HEADERS[7]?.width, children: actions })] }));
});
