import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Col, TextBody } from '../lib';
import { createStyles } from '../theme';
export const LabeledValue = memo(({ label, value }) => {
    return (_jsxs(Col.L, { style: $.container, children: [_jsx(TextBody, { children: label }), _jsx(Col, { style: $.space, children: typeof value === 'string' ? _jsx(TextBody, { em: true, children: value }) : value })] }));
});
const $ = createStyles({
    container: {
        flex: 1,
    },
    space: {
        marginTop: 2,
    },
});
