import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Col } from '../lib';
import { AmountRoR } from '../amount-format';
import { _MarketListItem } from './_market-list-item';
import { createStyles } from '../theme';
export const MarketListItemFund = memo(({ data, description, displayCode, ...rest }) => {
    return (_jsx(_MarketListItem, { ...rest, displayCode: displayCode, subtitle: description, coinIcon: null, addon: _jsxs(Col.R, { children: [_jsx(AmountRoR, { type: "mtd", value: data.mtdRoR }), _jsx(Col, { style: $.space, children: _jsx(AmountRoR, { type: "ytd", value: data.ytdRoR }) })] }) }));
});
const $ = createStyles({
    space: {
        marginTop: 2,
    },
});
