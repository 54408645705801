import ArrowDown from './arrow-down';
import ArrowUp from './arrow-up';
import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import CloseX from './close-x';
import ManageSearch from './manage-search';
import SearchLens from './search-lens';
export const SvgIcons = {
    'arrow-down': ArrowDown,
    'arrow-up': ArrowUp,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    'close-x': CloseX,
    'manage-search': ManageSearch,
    'search-lens': SearchLens,
};
