import { useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { AppContainer, Enriched } from 'common';
import { API } from 'api';
import { AppTablePaginator as Paginator } from '../app-table-paginator';
import { AppTableHeadings } from '../app-table/headings';
import { AppTableRows } from '../app-table/rows';
import { getTransactionsColumns } from './columns';
import { SortDirection, TransactionColumn } from '~/config';
import { useActivityFilterOptions } from './use-activity-filter-options';
import { SearchEmpty } from '@xbto/universal-components';
import { handleNavigation } from '../shared/url-params/url-params';
import { TRANSACTIONS_SORT_PARAMS_KEYS } from './transactions-filters/use-filter-transactions';
import { SortInfo } from '~/store/types';

interface Props {
  showRefresh?: boolean;
  isFund?: boolean;
}

export const TransactionsTable = ({ showRefresh = true, isFund }: Props) => {
  /**
   * Hooks
   */
  const {
    hasData,
    csvButton,
    isLoading,
    handleGetData,
    handleRowClick,
    paginatedActivities,
  } = useActivityFilterOptions();

  const {
    applyParams,
    queryParams: { sortColumn, sortDirection, ...rest },
  } = handleNavigation<TRANSACTIONS_SORT_PARAMS_KEYS>();
  useEffect(() => {
    handleGetData({ sortColumn, sortDirection, ...rest });
  }, [
    sortColumn,
    sortDirection,
    rest?.dateEnd,
    rest?.dateStart,
    rest?.statusFilter,
    rest?.activityLabels,
    rest?.currencyCodeFilter,
  ]);

  /**
   * Variables
   */
  const columnProps = useMemo(() => {
    return {
      sort: {
        column:
          TransactionColumn[sortColumn as TransactionColumn] ||
          TransactionColumn.Date,
        direction:
          SortDirection[sortDirection as SortDirection] || SortDirection.DESC,
      },
      onSort: (value: SortInfo<TransactionColumn>) =>
        applyParams({
          sortColumn: value?.column,
          sortDirection: value?.direction,
        }),
    };
  }, [sortColumn, sortDirection, applyParams]);

  const columns = getTransactionsColumns(columnProps, isFund);
  const handleEmptyTable = useCallback(
    (emptyData, withData) => {
      return isLoading ? emptyData : withData;
    },
    [isLoading]
  );

  const handlePagination = useCallback(
    arg => {
      applyParams(
        {
          page: arg.page,
        },
        { append: true, callBack: handleGetData }
      );
    },
    [applyParams, handleGetData]
  );

  /**
   * DOM
   */
  return (
    <div className="snap-x snap-mandatory min-w-7xl max-w-7xl overflow-x-auto">
      <div className="min-w-780">
        {/* content  */}
        <AppContainer
          cls="relative rounded-t overflow-auto"
          containerWidth="xl"
        >
          {/* export csv  */}
          {csvButton(showRefresh)}

          {/* transactions table header  */}
          <AppTableHeadings<
            TransactionColumn,
            Enriched.ActivityTransaction | null
          >
            borderCls={cx('border-grey-bright bg-white border', {
              'border-b-0': !isLoading && !hasData,
            })}
            columns={columns}
          />

          {/* transactions table rows  */}
          <AppTableRows<TransactionColumn, API.ActivityTransaction | null>
            columns={handleEmptyTable([], columns)}
            rows={handleEmptyTable(null, paginatedActivities?.result)}
            onRowClick={handleRowClick}
            containerCls="border border-t-0 grey-bright"
            emptyDataText={<SearchEmpty title="No activity found" />}
          />
        </AppContainer>
        {/* footer  */}
        <Paginator
          paginatedActivities={handleEmptyTable(null, paginatedActivities)}
          onChanged={handlePagination}
        />
      </div>
    </div>
  );
};
