import { withShadowDOM } from '../hoc';
import { MarketListHeader as Mlh } from './market-list-header';
import { MarketListItemBond as Mlib } from './market-list-item-bond';
import { MarketListItemCrypto as Mlic } from './market-list-item-crypto';
import { MarketListItemFiat as MliFiat } from './market-list-item-fiat';
import { MarketListItemFund as MliFund } from './market-list-item-fund';
import { MarketTableHeader as Mth } from './market-table-header';
export const MarketListHeader = withShadowDOM(Mlh);
export const MarketListItemBond = withShadowDOM(Mlib);
export const MarketListItemCrypto = withShadowDOM(Mlic);
export const MarketListItemFiat = withShadowDOM(MliFiat);
export const MarketListItemFund = withShadowDOM(MliFund);
export const MarketTableHeader = withShadowDOM(Mth);
/**
 * Note:
 * the table cells inside these row components are wrapped in shadowDOM optionally
 * only the actions row is not wrapped because it needs access to Context/ Store outside of the shadowDOM referred within the injected component
 * - This was the reason for the `.subscribe` error in the web app
 */
export * from './market-table-item-bond';
export * from './market-table-item-crypto';
export * from './market-table-item-fiat';
export * from './market-table-item-fund';
