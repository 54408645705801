import { jsx as _jsx } from "react/jsx-runtime";
import { API } from 'api';
import { useMemo } from 'react';
import { SortableHeaders, } from './sortable-headers';
export function SortableHeader({ defaultSortDir = API.SortDirection.Descending, id, label, ...rest }) {
    // Vars
    const items = useMemo(() => {
        return [{ id, label, defaultSortDir }];
    }, [defaultSortDir, id, label]);
    // Render
    return _jsx(SortableHeaders, { ...rest, items: items });
}
