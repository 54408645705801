import { API } from 'api';
import parseNumber from 'multi-number-parse';
import { SORT_ASSETS_FIELDS, SORT_DIR } from '../../config';
import {
  AccountLevelSettings,
  ActionId,
  Enriched,
  EnrichedAccountDetail,
  EnrichedAccountDetailAsset,
  EnrichedCurrencyInformation,
  GlobalAppSettings,
} from '../../types';
import { orderBy } from '../order-by';
import { formatAmount } from '../format-amount';
import { formatters } from '../formatters';
import { DATE_FORMATS, DEFAULTS } from '../../constants';
import { createBalanceChange } from './balance-change';
import {
  HoldingsTableColumn,
  HoldingsTableColumnId,
  HoldingsTableColumnTitle,
} from '../../store/portfolio/types';
import { DataModel } from '../../store';
import { formatReceiptDate } from '../date-utils';
import { format } from 'date-fns';
import { maskLead } from '../mask-lead';

const getColumns = (
  account: API.AccountProperties | null,
  isAdmin: boolean,
  client: DataModel['client']
): HoldingsTableColumn[] => {
  const isFundAccount = account?.accountType === API.AccountType.Fund;

  const result: HoldingsTableColumn[] = [
    {
      id: HoldingsTableColumnId.Asset,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Asset],
      sortable: true,
      sx: { width: '100%' },
    },
    {
      id: HoldingsTableColumnId.Quantity,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Quantity],
      sortable: true,
      sx: { width: '100%' },
    },
  ];

  if (!isFundAccount) {
    result.push({
      id: HoldingsTableColumnId.Price,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Price],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.Value,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Value],
      sortable: true,
      sx: { width: '60%' },
    });
    result.push({
      id: HoldingsTableColumnId.Change24h,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Change24h],
      sortable: true,
      sx: { width: '50%' },
    });
  } else {
    result.push({
      id: HoldingsTableColumnId.RateOfReturnMTD,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.RateOfReturnMTD],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.RateOfReturnYTD,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.RateOfReturnYTD],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.TotalReturn,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.TotalReturn],
      sortable: true,
      sx: { width: '50%' },
    });
  }

  if (account?.isYieldEligible) {
    result.push({
      id: HoldingsTableColumnId.APY,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.APY],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.TotalInterestEarned,
      title:
        HoldingsTableColumnTitle[HoldingsTableColumnId.TotalInterestEarned],
      sortable: true,
      sx: { width: '50%' },
    });
    result.push({
      id: HoldingsTableColumnId.AccruedInterestThisWeek,
      title:
        HoldingsTableColumnTitle[HoldingsTableColumnId.AccruedInterestThisWeek],
      sortable: true,
      sx: { width: '50%' },
    });
  }

  // is not admin user & only show action ... in web app
  if (
    (!isAdmin && client === 'web') ||
    (isFundAccount && client === 'mobile')
  ) {
    result.push({
      id: HoldingsTableColumnId.Action,
      title: HoldingsTableColumnTitle[HoldingsTableColumnId.Action],
      sortable: false,
      sx: { width: '25%' },
    });
  }

  return result;
};

export const enrichAccountDetail = (
  client: DataModel['client'],
  accountDetail: API.AccountDetail,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[],
  accounts: Enriched.ListAccountItem[],
  globalAppSettings: GlobalAppSettings | null,
  accountLevelSettings?: AccountLevelSettings | null
) => {
  if (!accountDetail?.assets || !currencies || !currencies.length) {
    return null;
  }

  const isAccountOfTypeProTrading =
    accountDetail.account?.accountType === API.AccountType.Trading ||
    accountDetail.account?.accountType === API.AccountType.ProTrading;
  const isAccountOfTypeVault =
    accountDetail.account?.accountType === API.AccountType.Custody ||
    accountDetail.account?.accountType === API.AccountType.Vault;
  const isAccountOfTypeFund =
    accountDetail.account?.accountType === API.AccountType.Fund;

  const enrichedAssets: EnrichedAccountDetailAsset[] = [];

  accountDetail.assets.forEach(item => {
    const currency = currencies.find(c => c.code === item.currency?.code);

    if (currency) {
      const balance = parseNumber(item.quantity || 0);
      enrichedAssets.push({
        ...item,
        canStabletagTransfer: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
        account: accountDetail.account,
        currency,
        isAccountOfTypeProTrading,
        isAccountOfTypeVault,
        isAccountOfTypeFund,
        balance,
        balanceUsd: parseNumber(item.quantityUsd || 0),
        hasBalance: !!balance,
        formatted: {
          earnedInterestThisWeekUsd: formatters.getCurrency(
            item.earnedInterestThisWeekUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          earnedInterestTotalUsd: formatters.getCurrency(
            item.earnedInterestTotalUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          balance: formatAmount(item.quantity, currency?.decimals),
          balanceWithCurrencyCode: formatters.getCurrency(
            item.quantity,
            currency?.decimals,
            currency.displayCode,
            fiatCurrencyCodes
          ),
          balanceUsd: formatAmount(item.quantityUsd, 2),
          balanceUsdWithCurrencyCode: formatters.getCurrency(
            item.quantityUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          pendingBalance: formatAmount(
            item.pendingQuantity,
            currency?.decimals
          ),
          balanceIncludingPending: formatters.getAmount(
            item.quantityIncludingPending,
            currency.decimals
          ),
          balanceIncludingPendingWithCurrencyCode: formatters.getCurrency(
            item.quantityIncludingPending,
            currency.decimals,
            currency.displayCode,
            fiatCurrencyCodes
          ),
          balanceIncludingPendingUsdWithCurrencyCode: formatters.getCurrency(
            item.quantityIncludingPendingUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          tradableQuantity: item.tradableQuantity || '0',
          tradableQuantityUsd: formatAmount(item.tradableQuantityUsd, 2),
          withdrawableQuantity: item.withdrawableQuantity || '0',
          //   formatAmount(
          //   item.withdrawableQuantity,
          //   DEFAULTS.MAX_DECIMALS,
          //   true,
          //   true
          // ),
          withdrawableQuantityUsd: formatAmount(
            item.withdrawableQuantityUsd,
            DEFAULTS.DECIMAL_SCALE
          ),
          withdrawableQuantityUsdWithCurrencyCode: formatters.getCurrency(
            item.withdrawableQuantityUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          pendingBalanceUsd: formatAmount(item.pendingQuantityUsd, 2),
          value: formatters.getCurrency(
            item.quantityIncludingPendingUsd,
            DEFAULTS.DECIMAL_SCALE,
            'USD',
            fiatCurrencyCodes
          ),
          mtdReturn: formatters.getCurrency(
            item.mtdNetReturns,
            item.fundDetails?.secondaryAsset?.decimals,
            item.fundDetails?.secondaryAsset?.displayCode || '',
            fiatCurrencyCodes
          ),
          rateOfReturnPercentMTD: parseNumber(item.mtdPercent || '0'),
          ytdReturn: formatters.getCurrency(
            item.ytdNetReturns,
            item.fundDetails?.secondaryAsset?.decimals,
            item.fundDetails?.secondaryAsset?.displayCode || '',
            fiatCurrencyCodes
          ),
          rateOfReturnPercentYTD: parseNumber(item.ytdPercent || '0'),
          totalReturnPercent: parseNumber(item.allReturnsPercent || '0'),
          totalReturn: formatters.getCurrency(
            item.allReturns,
            item.fundDetails?.secondaryAsset?.decimals,
            item.fundDetails?.secondaryAsset?.displayCode || '',
            fiatCurrencyCodes
          ),
          valueAsOfDateTime: item.fundDetails?.unitCostAsOf
            ? `Value as of ${format(
                new Date(item.fundDetails?.unitCostAsOf),
                DATE_FORMATS.MM_dd_yyyy
              )}`
            : undefined,
          balanceSecondaryAssetWithCurrencyCode:
            item.fundDetails?.valueIncludingPendingInSecondaryAsset &&
            item.fundDetails.secondaryAsset &&
            item.fundDetails.secondaryAsset.displayCode
              ? formatters.getCurrency(
                  item.fundDetails.valueIncludingPendingInSecondaryAsset,
                  item.fundDetails.secondaryAsset?.decimals,
                  item.fundDetails.secondaryAsset.displayCode,
                  fiatCurrencyCodes
                )
              : undefined,
        },
      });
    }
  });

  const orderedEnrichedAssets = orderBy(
    enrichedAssets,
    isAccountOfTypeFund ? [SORT_DIR.ASC] : [SORT_DIR.DESC, SORT_DIR.ASC],
    isAccountOfTypeFund
      ? [`currency.${SORT_ASSETS_FIELDS.NAME}`]
      : [SORT_ASSETS_FIELDS.BALANCE_USD, `currency.${SORT_ASSETS_FIELDS.NAME}`]
  );

  const enrichedAssetsWithBalanceOrEarnedInterest =
    orderedEnrichedAssets.filter(a => {
      return (
        parseNumber(a.quantity || 0) > 0 ||
        parseNumber(a.earnedInterestThisWeek || 0) > 0 ||
        parseNumber(a.pendingQuantity || 0) > 0
      );
    });

  const earnedInterestTotalUsd = Number(
    accountDetail.earnedInterestTotalUsd ?? '0'
  );
  const earnedInterestThisWeekUsd = Number(
    accountDetail.earnedInterestThisWeekUsd ?? '0'
  );

  const totalWithdrawableUsd = Number(
    accountDetail.totalWithdrawableUsd ?? '0'
  );
  const totalTradableUsd = Number(accountDetail.totalTradableUsd ?? '0');
  const totalPendingSettlementUsd = Number(
    accountDetail.totalPendingSettlementUsd ?? '0'
  );
  const totalBalanceIncludingPendingUsd = Number(
    accountDetail.totalBalanceIncludingPendingUsd ?? '0'
  );
  const totalOpenOrdersUsd = Number(accountDetail.totalOpenOrdersUsd ?? '0');
  const totalPendingOutgoingUsd = Number(
    accountDetail.totalPendingOutgoingUsd ?? '0'
  );
  const totalPendingSendUsd = Number(accountDetail.totalPendingSendUsd ?? '0');
  const totalPendingOutgoingTransferOutUsd = Number(
    accountDetail.totalPendingOutgoingTransferOutUsd ?? '0'
  );
  const totalPendingIncomingUsd = Number(
    accountDetail.totalPendingIncomingUsd ?? '0'
  );
  const totalPendingReceiveUsd = Number(
    accountDetail.totalPendingReceiveUsd ?? '0'
  );
  const totalPendingIncomingTransferInUsd = Number(
    accountDetail.totalPendingIncomingTransferInUsd ?? '0'
  );
  const totalFiatUsd = Number(accountDetail.totalFiatUsd ?? '0');
  const totalCryptoUsd = Number(accountDetail.totalCryptoUsd ?? '0');
  const totalBalanceUsd = Number(accountDetail.totalBalanceUsd ?? '0');

  const hasBalance = totalBalanceUsd > 0;
  const hasCrypto = totalCryptoUsd > 0;
  const hasFiat = totalFiatUsd > 0;

  const allowedActions: ActionId[] = [];

  // Trade
  if (accountDetail.canTrade && hasBalance) {
    allowedActions.push(ActionId.Buy);
    allowedActions.push(ActionId.Sell);
  }

  // Send
  if (accountDetail.canSendCrypto && hasCrypto && hasBalance) {
    allowedActions.push(ActionId.Send);
  }

  // Receive
  if (accountDetail.canReceiveCrypto) {
    allowedActions.push(ActionId.Receive);
  }

  // Transfer
  const hasManyAccounts = accounts.length > 1;

  if (
    hasManyAccounts &&
    (accountDetail.canReceiveAccountTransfer ||
      (hasBalance && accountDetail.canSendAccountTransfer))
  ) {
    allowedActions.push(ActionId.Transfer);
  }

  // Add cash
  if (accountDetail.canReceiveCash) {
    allowedActions.push(ActionId.AddCash);
  }

  // Widthdraw cash
  if (accountDetail.canSendCash && hasFiat && hasBalance) {
    allowedActions.push(ActionId.WithdrawCash);
  }

  let balanceChangeValue: string | null;
  let balanceChangePercent: string | null;

  const isFundAccount =
    accountDetail.account?.accountType === API.AccountType.Fund;

  if (isFundAccount) {
    balanceChangeValue = accountDetail.balanceChange1MonthUsd;
    balanceChangePercent = accountDetail.balanceChange1MonthPercent;
  } else {
    balanceChangeValue = accountDetail.balanceChange24HoursUsd;
    balanceChangePercent = accountDetail.balanceChange24HoursPercent;
  }

  const result: EnrichedAccountDetail = {
    ...accountDetail,
    balanceChange: createBalanceChange(
      balanceChangeValue,
      balanceChangePercent,
      fiatCurrencyCodes
    ),
    canStabletagTransfer: false, // Note: https://stablehouse.atlassian.net/browse/SH-5156
    assets: orderedEnrichedAssets,
    assetsWithBalanceOrEarnedInterest:
      enrichedAssetsWithBalanceOrEarnedInterest,
    settings: accountLevelSettings ?? null,
    allowedActions,
    formatted: {
      earnedInterestTotalUsd: formatAmount(earnedInterestTotalUsd, 2),
      earnedInterestThisWeekUsd: formatAmount(earnedInterestThisWeekUsd, 2),
      totalWithdrawableUsd: formatAmount(totalWithdrawableUsd, 2),
      totalTradeableUsd: formatAmount(totalTradableUsd, 2),
      totalPendingSettlementUsd: formatAmount(totalPendingSettlementUsd, 2),
      totalFiatUsd: formatAmount(totalFiatUsd, 2),
      totalCryptoUsd: formatAmount(totalCryptoUsd, 2),
      totalBalanceUsd: formatAmount(totalBalanceUsd, 2),
      totalBalanceIncludingPendingUsd: formatAmount(
        totalBalanceIncludingPendingUsd,
        2
      ),
      totalOpenOrdersUsd: formatAmount(totalOpenOrdersUsd, 2),
      totalPendingOutgoingUsd: formatAmount(totalPendingOutgoingUsd, 2),
      totalPendingSendUsd: formatAmount(totalPendingSendUsd, 2),
      totalPendingOutgoingTransferOutUsd: formatAmount(
        totalPendingOutgoingTransferOutUsd,
        2
      ),
      totalPendingIncomingUsd: formatAmount(totalPendingIncomingUsd, 2),
      totalPendingReceiveUsd: formatAmount(totalPendingReceiveUsd, 2),
      totalPendingIncomingTransferInUsd: formatAmount(
        totalPendingIncomingTransferInUsd,
        2
      ),
      maskedAccountNumber: accountDetail.account?.accountNumber
        ? maskLead(accountDetail.account.accountNumber)
        : null,
    },
    hasEarnedInterestEver:
      !!accountLevelSettings?.canEarnYield &&
      (earnedInterestTotalUsd > 0 || earnedInterestThisWeekUsd > 0),
    isAccountOfTypeProTrading,
    isAccountOfTypeVault,
    isAccountOfTypeFund,
    hasBalance,
    hasCrypto,
    hasFiat,
    holdingsTable: {
      columns: getColumns(
        accountDetail.account,
        globalAppSettings?.userType === API.UserType.ClientAdminReadWrite,
        client
      ),
    },
    labels: {
      holdingsTableTitle: isFundAccount ? `Holdings` : `Holdings and prices`,
      holdingsTableSubtitle: isFundAccount
        ? `Updated on a monthly basis`
        : `Updated as of ${formatReceiptDate(new Date())}`,
    },
  };
  return result;
};
