import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { AmountChangePercent, AmountAvg, AmountPrice } from '../amount-format';
import { TableCell, TableRow } from '../table';
import { TableCellAsset } from './table-cell-asset';
import { CRYPTO_HEADERS as HEADERS } from './market-table-header';
export const MarketTableItemCrypto = memo(({ actions, currencyCode, data, onPress, ...rest }) => {
    // Handlers
    const handlePress = useCallback(() => {
        onPress?.(currencyCode);
    }, [currencyCode, onPress]);
    const cellProps = {
        align: 'right',
        onPress: onPress ? handlePress : undefined,
    };
    /**
     * DOM
     */
    return (_jsxs(TableRow, { children: [_jsx(TableCellAsset, { ...rest, onPress: onPress ? handlePress : undefined, width: HEADERS[0]?.width }), _jsx(TableCell, { width: HEADERS[1]?.width, ...cellProps, children: _jsx(AmountPrice, { color: "onSurfaceEm", value: data.price, em: true }) }), _jsx(TableCell, { width: HEADERS[2]?.width, ...cellProps, children: _jsx(AmountChangePercent, { value: data.change24hPercent, em: true }) }), _jsx(TableCell, { width: HEADERS[3]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountAvg, { color: "onSurfaceEm", value: data.volume24h, em: true }) }), _jsx(TableCell, { width: HEADERS[4]?.width, ...cellProps, renderInShadowDOM: true, children: _jsx(AmountAvg, { color: "onSurfaceEm", value: data.marketCap, em: true }) }), _jsx(TableCell, { width: HEADERS[5]?.width, children: actions })] }));
});
