import { Link } from 'react-router-dom';
import { dataTestIds, Enriched, maskHash } from 'common';
import {
  AppTableHeadings,
  AppTableRows,
  Props as ColumnHeaderProps,
  SortProps,
  useAppTableSort,
} from '../../app-table';
import { getAccountType } from '~/utils/get-account-details';

export enum Column {
  AccountName = 'AccountName',
  AccountNumber = 'AccountNumber',
  Balance = 'Balance',
  Action = 'Action',
}

export const headings = {
  [Column.AccountName]: 'Account Name',
  [Column.AccountNumber]: 'Account Number',
  [Column.Balance]: 'Balance',
};

const getAccountNameColumn = (
  props: SortProps<Column>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    sx: {
      width: '100%',
    },
    showSort: false,
    justify: 'justify-start ',
    name: Column.AccountName,
    cls: 'overflow-hidden',
    cell: (data: Enriched.ListAccountItem | null) => {
      return (
        <div className="flex flex-row items-center gap-x-6 justify-between w-full">
          <div className="flex flex-col">
            <span className="flex flex-row items-center gap-2">
              <span className="text-sm font-bold truncate">
                {data?.account?.label || '-'}
              </span>
              <span className="text-xs font-medium bg-grey-bright p-1 relative rounded-sm">
                {getAccountType(data?.account)}
              </span>
            </span>
            <span className="text-xs text-grey-darker font-normal">
              {maskHash(data?.account?.accountNumber || '')}
            </span>
          </div>
          <span className="text-sm font-bold">{data?.formatted.totalUsd}</span>
        </div>
      );
    },
  };
};

export const getListOfAccountsColumns = (
  columnProps: SortProps<Column>
): ColumnHeaderProps<Column, Enriched.ListAccountItem | null>[] => {
  return [getAccountNameColumn(columnProps, headings[Column.AccountName])];
};

interface Props {
  accounts: Enriched.ListAccountItem[] | null;
  to: (row: Enriched.ListAccountItem | null) => string;
}

export const PortfolioAccountsList = ({ accounts, to }: Props) => {
  const { columnProps } = useAppTableSort<Column>();
  const columns = getListOfAccountsColumns(columnProps);

  /**
   * DOM
   */

  return (
    <div
      data-testid={dataTestIds.components.portfolioHero.listAccounts}
      className={'pb-6'}
    >
      <AppTableHeadings
        title={'Accounts'}
        columns={[]}
        titleCls="text-base"
        containerCls="pt-4"
        borderCls="rounded-t-4 border-1 border-b-0 bg-white grey-bright"
      />

      <AppTableRows<Column, Enriched.ListAccountItem | null>
        as={Link}
        to={to}
        columns={columns}
        rows={accounts}
        rowPadding="p-4 md:px-8"
        emptyDataText={`No fund(s) data`}
      />
    </div>
  );
};
