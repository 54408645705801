import {
  AppDialogProvider,
  CurrencyInfoContext,
  useComputedConfig,
  useEnvironmentConfig,
} from 'common';
import { FC, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FourOFour } from '~/components/404';
import { AccountDisabled } from '~/components/404/account-disabled';
import { AccountLocked } from '~/components/404/account-locked';
import { AccountUnknown } from '~/components/404/account-unkown';
import { Restricted404 } from '~/components/404/restricted-404';
import { Unrestricted429 } from '~/components/404/unrestricted-429';
import { Unrestricted500x } from '~/components/404/unrestricted-500x';
import { RequireAuth } from '~/components/auth/require-auth';
import { RequireAuthExternal } from '~/components/auth/require-auth-external';
import { TransactionWorkflows } from '~/components/dashboard/transaction-workflows';
import { AdminPage } from '~/pages/admin';
import { AssetPage } from '~/pages/asset';
import { ContactUs } from '~/pages/contact-us';
import { ForgotPassword } from '~/pages/forgot-password';
import { Login } from '~/pages/login';
import { Login2fa } from '~/pages/login-2fa';
import { MarketsV2Page } from '~/pages/markets-v2';
import { PortfolioPage } from '~/pages/portfolio';
import { ConfirmEmailAddress } from '~/pages/register/confirm-email-address';
import { EmailVerificationPending } from '~/pages/register/email-verification-pending';
import { EmailVerified } from '~/pages/register/email-verified';
import { SettingsPage } from '~/pages/settings';
import { StatementsPage } from '~/pages/statements';
import { APP_ROUTES } from '~/routes';
import { DataStore } from '~/store';
import { AppDebugDialogs } from './debug';
import { AppStartup } from './startup';
import { JumpToPortfolio } from '~/pages/jump-to-portfolio';
import { OauthCallback } from '~/pages/oauth-callback';
import { LoggedOut } from '~/pages/logged-out';
import { useOnFocusAuthCheck } from '~/hooks/use-authentication-hooks';

export const AppRouter: FC = () => {
  /**
   * Store
   */
  const isAuthenticated = DataStore.useStoreState(s => s.user.isAuthenticated);
  const allowApiKeys = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.allowApiKeys
  );
  const allowReferralRewards = DataStore.useStoreState(
    s => !!s.settings.globalAppSettings?.allowReferralRewards
  );
  const allowDeposits = DataStore.useStoreState(
    s => !!s.portfolio.accountDetail?.settings?.allowDeposits
  );
  const clientUserType = DataStore.useStoreState(s => s.user.clientUserType);
  const currencies = DataStore.useStoreState(s => s.metaData.currencies);

  const { disconnectStreamingUpdates } = DataStore.useStoreActions(_ => ({
    disconnectStreamingUpdates: _.metaData.disconnectStreamingUpdates,
  }));

  /**
   * Hooks
   */
  const { REACT_APP_BUILD_ENV_NAME } = useEnvironmentConfig();
  const { tenant } = useComputedConfig();
  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    if (!disconnectStreamingUpdates) {
      return;
    }
    disconnectStreamingUpdates();
  }, [isAuthenticated]);

  useOnFocusAuthCheck();

  /**
   * DOM
   */
  return (
    <CurrencyInfoContext.Provider value={currencies}>
      <AppDialogProvider>
        <AppStartup>
          <Routes>
            {/* routes: pre authentication */}
            {[
              APP_ROUTES.NON_AUTH_HOME,
              // Note: Support redirect for users who may have bookmarked `login-auth0`
              APP_ROUTES.NON_AUTH_LOGIN_AUTH0,
            ].map(path => (
              <Route
                key={path}
                path={path}
                element={
                  <RequireAuthExternal>
                    <Login />
                  </RequireAuthExternal>
                }
              />
            ))}
            {/* Note: support `login-legacy` login for lower regions for testing purposes */}
            {REACT_APP_BUILD_ENV_NAME !== 'stablehouse.com' && (
              <Route
                path={APP_ROUTES.NON_AUTH_LOGIN_LEGACY}
                element={
                  <RequireAuthExternal>
                    <Login legacyAuth />
                  </RequireAuthExternal>
                }
              />
            )}
            <Route
              path={APP_ROUTES.NON_AUTH_LOGIN_2FA}
              element={
                <RequireAuthExternal>
                  <Login2fa />
                </RequireAuthExternal>
              }
            />
            <Route
              path={APP_ROUTES.NON_AUTH_FORGOT_PASSWORD}
              element={
                <RequireAuthExternal>
                  <ForgotPassword />
                </RequireAuthExternal>
              }
            />
            <Route
              path={APP_ROUTES.NON_AUTH_LOGGED_OUT}
              element={
                <RequireAuthExternal>
                  <LoggedOut />
                </RequireAuthExternal>
              }
            />
            <Route
              path={APP_ROUTES.NON_AUTH_CONTACT_US}
              element={<ContactUs />}
            />
            <Route
              path={APP_ROUTES.NON_AUTH_CONFIRM_EMAIL_ADDRESS}
              element={<ConfirmEmailAddress />}
            />

            {/* ============== */}
            {/* routes: after authentication */}
            {/* ============== */}
            {/* route: admin  */}
            {clientUserType === 'admin' && (
              <Route path={APP_ROUTES.AUTH_ADMIN} element={<AdminPage.Root />}>
                <Route index element={<AdminPage.Default />} />
                <Route path="funds/:fundId" element={<Outlet />}>
                  <Route
                    path="accounts"
                    element={<AdminPage.Fund.Accounts />}
                  />
                  <Route
                    path="accounts/:fundAccountId"
                    element={<AdminPage.Fund.AccountDetail />}
                  />
                </Route>
                <Route path="approval" element={<AdminPage.QuorumApproval />} />
                <Route
                  path="pending-approvals"
                  element={<AdminPage.QuorumPendingApprovals />}
                />
              </Route>
            )}

            {/* route: legacy route support */}
            <Route
              path={APP_ROUTES.AUTH_LEGACY_DASHBOARD}
              element={<JumpToPortfolio />}
            />
            <Route
              path={APP_ROUTES.AUTH_LEGACY_TRANSACTIONS}
              element={<JumpToPortfolio />}
            />

            {/* route: portfolio/home  */}
            <Route
              path={APP_ROUTES.AUTH_PORTFOLIO}
              element={<PortfolioPage.Root />}
            >
              <Route index element={<PortfolioPage.Default />} />
              <Route
                index
                path="overview"
                element={<PortfolioPage.ListOfAccounts />}
              />
              <Route path=":id" element={<PortfolioPage.AccountDetail />} />
            </Route>

            {/* route: markets  */}
            <Route
              path={APP_ROUTES.AUTH_MARKETS}
              element={<MarketsV2Page.ListOfAssets />}
            />

            {/* route: statements  */}
            <Route
              path={APP_ROUTES.AUTH_STATEMENTS}
              element={<StatementsPage.Root />}
            >
              <Route index element={<StatementsPage.ListOfStatements />} />
            </Route>

            {/* route: assets  */}
            <Route path={APP_ROUTES.AUTH_ASSET} element={<Outlet />}>
              <Route path=":id" element={<AssetPage.Detail />} />
            </Route>

            {/* route: settings  */}
            <Route
              path={APP_ROUTES.AUTH_SETTINGS}
              element={<SettingsPage.Root />}
            >
              <Route index element={<SettingsPage.Details />} />
              <Route path="details" element={<SettingsPage.Details />} />
              <Route path="security" element={<SettingsPage.Security />}>
                <Route path="password" element={<SettingsPage.Security />} />
                <Route path="2fa" element={<SettingsPage.Security />} />
              </Route>
              <Route
                path="email-notifications"
                element={<SettingsPage.Notifications />}
              />
              <Route
                path="bank-accounts"
                element={<SettingsPage.BankAccounts />}
              />
              <Route
                path="account-labels"
                element={<SettingsPage.AccountLabels />}
              />
              {tenant !== 'Apex' && (
                <Route
                  path="crypto-addresses"
                  element={<SettingsPage.AddressBook />}
                />
              )}
              {tenant !== 'Apex' && (
                <Route
                  path="crypto-addresses/:addressId"
                  element={<SettingsPage.AddressBookDetails />}
                />
              )}
              {allowApiKeys && (
                <Route path="api-keys" element={<SettingsPage.ApiKeys />} />
              )}
            </Route>
            <Route
              path={APP_ROUTES.AUTH_VERIFY_ADDRESS}
              element={<JumpToPortfolio />}
            />
            <Route
              path={APP_ROUTES.AUTH_EMAIL_VERIFICATION_SUCCESSFUL}
              element={<EmailVerified />}
            />
            <Route
              path={APP_ROUTES.NON_AUTH_EMAIL_VERIFICATION_PENDING}
              element={<EmailVerificationPending />}
            />

            <Route
              path={APP_ROUTES.AUTH_KYC_INDEX}
              element={<JumpToPortfolio />}
            />
            <Route
              path={APP_ROUTES.AUTH_KYC_SPLASH}
              element={<JumpToPortfolio />}
            />
            <Route
              path={APP_ROUTES.AUTH_KYC_WIZARD}
              element={<JumpToPortfolio />}
            />
            <Route
              path={APP_ROUTES.AUTH_KYC_EDD}
              element={<JumpToPortfolio />}
            />

            {allowReferralRewards && (
              <Route
                path={APP_ROUTES.AUTH_REFER_A_FRIEND}
                element={<JumpToPortfolio />}
              />
            )}
            {allowDeposits && (
              <Route
                path={APP_ROUTES.AUTH_WELCOME_BONUS}
                element={<JumpToPortfolio />}
              />
            )}
            <Route
              path={APP_ROUTES.AUTH_404}
              element={
                <RequireAuth>
                  <Restricted404 />
                </RequireAuth>
              }
            />
            {/* special routes  */}

            <Route
              path={APP_ROUTES.NON_AUTH_429}
              element={<Unrestricted429 />}
            />
            <Route
              path={APP_ROUTES.NON_AUTH_500x}
              element={<Unrestricted500x />}
            />
            <Route
              path={APP_ROUTES.NON_AUTH_ACCOUNT_DISABLED}
              element={<AccountDisabled />}
            />
            <Route
              path={APP_ROUTES.NON_AUTH_ACCOUNT_LOCKED}
              element={<AccountLocked />}
            />
            <Route
              path={APP_ROUTES.NON_AUTH_ACCOUNT_UNKNOWN}
              element={<AccountUnknown />}
            />
            <Route
              path={APP_ROUTES.AUTH_AUTH0_CALLBACK}
              element={<OauthCallback />}
            />
            <Route path="*" element={<FourOFour />} />
          </Routes>
        </AppStartup>

        {/* dialogs: workflows/ transactions  */}
        <TransactionWorkflows />

        {/* dialogs: debug dialogs  */}
        <AppDebugDialogs isAuthenticated={!!isAuthenticated} />

        {/* toasts  */}
        <ToastContainer />
      </AppDialogProvider>
    </CurrencyInfoContext.Provider>
  );
};
