import { jsx as _jsx } from "react/jsx-runtime";
import { Platform, StyleSheet, Text } from 'react-native';
import { theme } from '@xbto/design-system';
import { shorthandToFontProps } from '../../utils';
var Variants;
(function (Variants) {
    Variants["body1"] = "body1";
    Variants["body2"] = "body2";
    Variants["heading1"] = "heading1";
})(Variants || (Variants = {}));
const FontFamilies = {
    InterWeb: { fontFamily: 'Inter' },
    InterRegular: { fontFamily: 'Inter-Regular' },
    InterBold: { fontFamily: 'Inter-Bold' },
};
export const PlatformsFontRegular = Platform.select({
    android: FontFamilies.InterRegular,
    ios: FontFamilies.InterRegular,
    web: FontFamilies.InterWeb,
});
export const PlatformsFontBold = Platform.select({
    android: FontFamilies.InterBold,
    ios: FontFamilies.InterBold,
    web: FontFamilies.InterWeb,
});
const $ = StyleSheet.create({
    textBase: {
        // TODO(Hadrien): Until we have the proper transformer in DS,
        //  I break it manually for 1st PR
        ...shorthandToFontProps(theme.Body.Base.Regular),
        color: theme.color.text.primary,
        ...PlatformsFontRegular,
    },
    textBaseBold: {
        ...shorthandToFontProps(theme.Body.Base.Bold),
        color: theme.color.text.primary,
        ...PlatformsFontBold,
    },
    textMedium: {
        ...shorthandToFontProps(theme.Body.Medium.Regular),
        color: theme.color.text.primary,
        ...PlatformsFontRegular,
    },
    textMediumBold: {
        ...shorthandToFontProps(theme.Body.Medium.Regular),
        color: theme.color.text.primary,
        ...PlatformsFontBold,
    },
    textHeading1: {
        // TODO(Hadrien): Create a custom transformer in design system package to extract headings as Heading1, Heading2, ...
        ...shorthandToFontProps(theme.Heading['1']),
        color: theme.color.text.primary,
        ...PlatformsFontBold,
    },
});
const StyleVariants = {
    true: {
        [Variants.body1]: $.textBase,
        [Variants.body2]: $.textMedium,
        [Variants.heading1]: $.textHeading1,
    },
    false: {
        [Variants.body1]: $.textBaseBold,
        [Variants.body2]: $.textMediumBold,
        [Variants.heading1]: $.textHeading1,
    },
};
export const Typography = ({ children, variant, bold }) => {
    let styles = StyleVariants[`${!!bold}`][variant];
    return _jsx(Text, { style: styles, children: children });
};
