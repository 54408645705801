import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Pressable } from 'react-native';
import { createStyles } from '../theme';
export function ListItem({ children, disabled = false, id, onPress }) {
    // Handlers
    const handlePress = useCallback(() => {
        onPress?.(id);
    }, []);
    // Render
    return (_jsx(Pressable, { disabled: disabled && !onPress, onPress: handlePress, style: ({ pressed }) => [
            $.container,
            pressed && $.pressed,
            disabled && $.disabled,
        ], children: children }));
}
const $ = createStyles({
    container: {
        backgroundColor: 'surface',
        borderBottomWidth: 1,
        borderColor: 'outline',
        p: 'm',
    },
    pressed: {
        backgroundColor: 'surfaceActive',
    },
    disabled: {
        opacity: 0.4,
    },
});
