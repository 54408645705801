import cx from 'classnames';
import {
  ALLOWED_FILTER_ASSETS_BY_LIST,
  CurrencyIcon,
  EnrichedAccountDetailAsset,
  FormHeader,
  PriceCell,
  SearchInput,
  TradeFormValues,
  getValuePlaceholder,
  useCurrencyInfoContext,
  useWorkflowBuySellList,
} from 'common';
import { ChangeEvent, FC, PropsWithChildren, memo, useCallback } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '~/store';
import { TRADE_STEPS } from './steps';
import { SegmentedControl } from '~/components/lib';
import { AppLoader } from '~/components/app-loader';
import { API } from 'api';
import { NotAllowed } from '../shared/not-allowed';
import { getAccountType } from '~/utils/get-account-details';
import { SearchEmpty } from '@xbto/universal-components';

interface AssetItemProps {
  asset: EnrichedAccountDetailAsset;
  disabled: boolean;
  showPercentageChange?: boolean;
  onSelected: (asset: EnrichedAccountDetailAsset) => void;
}

const AssetItem: FC<AssetItemProps> = ({
  asset,
  onSelected,
  disabled,
  showPercentageChange = false,
}) => {
  /**
   * Methods
   */
  const handleOnSelected = useCallback(() => {
    if (disabled) {
      return;
    }
    onSelected(asset);
  }, [asset, onSelected, disabled]);
  /**
   * DOM
   */
  return (
    <div
      className={cx(
        'flex flex-row w-full justify-between items-center px-2 border-b hover-bg-grey-brighter',
        {
          'cursor-pointer': !disabled,
          'bg-grey-brighter cursor-not-allowed opacity-50': disabled,
        }
      )}
      style={{
        ...(disabled && {
          filter: 'grayscale(100%)',
        }),
      }}
      onClick={handleOnSelected}
    >
      <p className="flex flex-1 gap-x-2.5 px-5 py-3 items-center justify-between">
        <CurrencyIcon
          showLabel
          size={32}
          currencyCode={asset.currency.code}
          currencyName={asset.currency.name}
        />

        <span className="flex flex-col">
          <span className="text-sm font-bold text-right">
            {showPercentageChange
              ? asset.currency.fxRate.rateWithCurrencyCodeFormatted
              : asset.formatted.value}
          </span>
          {showPercentageChange ? (
            <>
              {!asset.currency.fxRate.rate24HoursAgoPercentageChange ? (
                getValuePlaceholder()
              ) : (
                <PriceCell
                  cls="text-right"
                  fontWeightCls="text-xs font-bold"
                  textSizeCls="text-xs"
                  value={asset.currency.fxRate.rate24HoursAgoPercentageChange}
                />
              )}
            </>
          ) : (
            <span className="text-xs text-right text-grey-darker">
              {asset.formatted.balanceWithCurrencyCode}
            </span>
          )}
        </span>
      </p>
    </div>
  );
};

interface AssetAllowedProps extends PropsWithChildren {
  busy: boolean;
  allowed: boolean;
  formValues: TradeFormValues;
  assetCode: string;
}

const AssetAllowed: FC<AssetAllowedProps> = ({
  busy,
  allowed,
  assetCode,
  formValues,
  children,
}) => {
  /**
   * Store
   */
  const setSelectedDialogType = AppStore.useStoreActions(
    a => a.setDashboardSelectedDialogType
  );
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);

  /**
   * DOM
   */
  if (busy) {
    return <AppLoader bgColor={'bg-transparent'} spinnerTop="50%" />;
  }
  if (allowed) {
    return <>{children}</>;
  }
  return (
    <NotAllowed
      message={`You cannot ${formValues.side.toLocaleLowerCase()} ${assetCode} in your account ${getAccountType(
        accountDetail?.account
      )} ${accountDetail?.account?.accountNumber}`}
      onClose={() => setSelectedDialogType(null)}
    />
  );
};

export const TradeList: FC<Partial<StepWizardChildProps>> = memo(
  ({ goToNamedStep }) => {
    if (!goToNamedStep) {
      console.error(
        `Component: AssetSelector, can only be used as a child of StepWizard.`
      );
      return null;
    }

    /**
     * Store
     */
    const selectedCurrencyCode = AppStore.useStoreState(
      s => s.selectedCurrencyCode
    );
    const setSelectedDialogType = AppStore.useStoreActions(
      a => a.setDashboardSelectedDialogType
    );

    /**
     * Vars
     */
    const selectedCurrency = useCurrencyInfoContext(selectedCurrencyCode);

    /**
     * Methods
     */
    const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      if (!setAssetSearch) {
        return;
      }
      setAssetSearch(e.target.value || '');
    }, []);
    const onBackHandler = useCallback(() => {
      goToNamedStep(TRADE_STEPS.FORM);
    }, [goToNamedStep]);
    // Note: do not memoize / useCallback this handler
    const onAssetSelected = (asset: EnrichedAccountDetailAsset) => {
      setAssetSearch('');
      setFormValues({
        ...formValues,
        ...(formValues.fromAsset ? { toAsset: asset } : { fromAsset: asset }),
        ...(!formValues.amountSide && !formValues.fromAsset
          ? {
              amountSide: 'fromAssetAmount',
            }
          : undefined),
      });

      goToNamedStep(TRADE_STEPS.FORM);
    };

    /**
     * Hooks
     */
    const {
      error,
      formValues,
      assetFilter,
      assetSearch,
      allowed,
      groupedAssets,
      hasNoAssets,
      assetListTitle,
      busy,
      assetListHeader,
      setAssetSearch,
      setAssetFilter,
      setFormValues,
    } = useWorkflowBuySellList({
      DataStore,
      ccyCode: selectedCurrency?.code,
      onSelected: onAssetSelected,
    });

    /**
     * DOM
     */
    return (
      <div className="flex flex-col min-h-250">
        {/* header  */}
        <FormHeader
          title={assetListTitle}
          {...(formValues?.fromAsset && { onBack: onBackHandler })}
        />

        <>
          {!!error ? (
            <NotAllowed
              message={error}
              onClose={() => setSelectedDialogType(null)}
            />
          ) : (
            <AssetAllowed
              busy={busy}
              allowed={allowed}
              formValues={formValues}
              assetCode={
                selectedCurrency?.displayCode || selectedCurrency?.code || ''
              }
            >
              <div className="flex flex-col gap-6 mx-10">
                {/* search input */}
                <SearchInput
                  autoFocus
                  disabled={busy}
                  value={assetSearch}
                  onChange={handleSearch}
                  handleClearInput={() => setAssetSearch('')}
                  cls=""
                />

                {/* predefined filters  */}
                {!hasNoAssets && (
                  <div className="mb-6">
                    <SegmentedControl
                      item={assetFilter}
                      type="button"
                      disabled={busy}
                      itemTemplate={item => (
                        <span className="capitalize">{item}</span>
                      )}
                      items={ALLOWED_FILTER_ASSETS_BY_LIST}
                      onSelected={value => setAssetFilter(value)}
                    />
                  </div>
                )}
              </div>
              {!hasNoAssets && (
                <p className="flex flex-row justify-between items-center bg-grey-brightest text-neutral-600 font-bold typo-b5 py-4 px-7">
                  <span>{assetListHeader.name}</span>
                  <span>{assetListHeader.value}</span>
                </p>
              )}
              <section className="flex flex-col rounded-b min-h-250 max-h-485 overflow-y-scroll">
                {hasNoAssets && (
                  <SearchEmpty subtitle={assetSearch ? undefined : ''} />
                )}
                {groupedAssets.map((group, index) => {
                  if (!group) {
                    return null;
                  }
                  const hasData = group.data && group.data.length > 0;
                  return (
                    <div
                      key={JSON.stringify(group)}
                      className={
                        index + 1 === groupedAssets.length ? 'mb-6' : ''
                      }
                    >
                      {group.title && hasData && (
                        <span
                          className={cx(
                            'flex flex-row w-full justify-center items-center border-b px-2 bg-grey-brighter text-sm font-bold py-4'
                          )}
                        >
                          {group.title}
                        </span>
                      )}
                      {group.data.map((asset, _index, arr) => {
                        return (
                          <AssetItem
                            key={asset.currency.name}
                            asset={asset}
                            disabled={group.key === 'nonTradable'}
                            onSelected={onAssetSelected}
                            showPercentageChange={
                              (formValues.side === API.Side.Buy &&
                                !formValues.fromAsset) ||
                              (formValues.side === API.Side.Sell &&
                                !!formValues.fromAsset)
                            }
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </section>
            </AssetAllowed>
          )}
        </>
      </div>
    );
  }
);
