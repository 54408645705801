import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { formatters } from 'common';
import { Label } from './label';
export const _PercentageLabel = ({ title, value, ...rest }) => {
    // Vars
    const formatted = useMemo(() => {
        return formatters.getAmount(value, 1);
    }, [value]);
    // Render
    return _jsx(Label, { ...rest, title: `${formatted}% ${title}` });
};
