import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { AmountChangePercent } from './amount-change-percent';
import { TextBody, Row } from '../lib';
import { createStyles } from '../theme';
export const AmountRoR = memo(({ type, ...rest }) => {
    return (_jsxs(Row.C, { style: $.container, children: [_jsx(AmountChangePercent, { ...rest }), _jsx(TextBody, { children: type.toUpperCase() })] }));
});
const $ = createStyles({
    container: {
        columnGap: 2,
    },
});
